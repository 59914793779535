//base imports and third party
import React from 'react';
import { connect } from 'react-redux';

//services and utils
import { state } from '../../store';
import { signUpSelector } from '../authentication/store';
import { dashboardActions, dashboardSelector } from './store';
import history from '../helper/history';

//components
import DeviceContainer from './deviceContainer';
import BrowserContainer from './browserContainer';
import { toast } from 'react-toastify';

//assets and css

export const Dashboard = connect(
  state.select({
    err: signUpSelector(state => state.err, ''),
    user: signUpSelector(state => state.user),
    deviceData: dashboardSelector(state => state.deviceData, [])
  }),
  state.actions(dashboardActions, [
    'loadDevicesData',
    'editDevice',
    'deleteDevice'
  ])
)(
  class Dashboard extends React.Component {
    componentDidMount() {
      document.title = 'Dashboard | Vidami';
      this.props.loadDevicesData();
    }
    render() {
      const { deviceData } = this.props;
      //actions
      const { deleteDevice, editDevice } = this.props;

      const onInstall = () => {
        if (deviceData.length) {
          history.push(`/onboarding/step4/install`);
        } else {
          toast.info('Please register your device.');
        }
      };

      return (
        <div className="dashboard-container container">
          <div className="dashboard-header">
            <div>
              <p className="dashboard-title">My Devices</p>
            </div>
            <button
              className="add-device-button"
              onClick={() => history.push(`/add-device`)}
            >
              + Add Device
            </button>
          </div>
          <div className="row">
            {deviceData.length ? (
              deviceData.map((data, index) => {
                return (
                  <DeviceContainer
                    data={data}
                    key={index}
                    deleteDevice={deleteDevice}
                    editDevice={editDevice}
                  />
                );
              })
            ) : (
              <div className="w-100 d-flex justify-content-center">
                <div className="text-center mt-3">Please add devices.</div>
              </div>
            )}
          </div>
          <div>
            <p className="dashboard-title software-device-header">
              Available Software
            </p>
          </div>
          <div className="row">
            <BrowserContainer onInstall={onInstall} />
          </div>
        </div>
      );
    }
  }
);
