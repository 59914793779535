//base imports and third party
import React from 'react';
import ReactLoading from 'react-loading';

const defaults = {
  type: 'spin',
  delay: 2000,
  color: '#ffffff'
};

export const ButtonWithLoader = ({ buttonText, loading, disabled = false }) => {
  return (
    <div>
      <button
        type="submit"
        className="registration-button"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        disabled={disabled}
      >
        {loading && (
          <ReactLoading
            type={defaults.type}
            color={defaults.color}
            width={'5%'}
            height={'unset'}
          />
        )}
        <div>&nbsp;&nbsp;{buttonText}</div>
      </button>
    </div>
  );
};
