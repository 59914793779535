import { call, spawn, take, putResolve } from '@redux-saga/core/effects';
import { toast } from 'react-toastify';
import history from '../helper/history';
import { api } from '../helper/api';
import { registerDevicesActions } from './store';
import {
  sendAmplitudeData,
  setAmplitudeUserId
} from '../../utilities/amplitude';

function* storeDeviceDetailsSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        registerDevicesActions.storeDevice().type
      );
      yield putResolve(registerDevicesActions.setLoading(true));
      const result = yield call(api, `/api/store-device-details`, 'POST', data);
      if (result.success === false) {
        yield putResolve(registerDevicesActions.loadError(result.message));
        yield putResolve(registerDevicesActions.setLoading(false));
      } else {
        yield putResolve(registerDevicesActions.setLoading(false));
        toast.success(result.message, {
          className: 'toast-success',
          style: { width: '21rem' }
        });

        setAmplitudeUserId(data.email);
        sendAmplitudeData(`Added the Device`, data.eventProperties);
        if (data.addDevice) {
          let event_properties = {
            pageUrl: `${process.env.REACT_APP_PUBLICURL}/dashboard`,
            pageTitle: 'Dashboard-Page',
            referrerUrl: document.referrer
          };
          setTimeout(() => {
            sendAmplitudeData(`Visited the dashboard`, event_properties);
            history.push('/dashboard');
          }, 1500);
        } else {
          let event_properties = {
            pageUrl:
              process.env.REACT_APP_PUBLICURL + '/onboarding/step4/install',
            pageTitle: 'Installation-Page',
            referrerUrl: document.referrer
          };
          setTimeout(() => {
            sendAmplitudeData(
              `Visited the installation page`,
              event_properties
            );
            history.push('/onboarding/step4/install');
          }, 1500);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export function* getSingleDeviceDetailsSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        registerDevicesActions.getSingleDeviceDetails().type
      );
      const result = yield call(
        api,
        `/api/single-device-details?id=${data.id}`
      );
      if (result.success === true && result.data) {
        const { id, name, serialNumber } = result.data;
        yield putResolve(
          registerDevicesActions.handleDeviceSerialNumberChange({
            deviceSerialNumber: serialNumber,
            name
          })
        );
        yield putResolve(
          registerDevicesActions.deviceChangeHandler({
            deviceName: name,
            deviceSerialNumber: serialNumber
          })
        );
        yield putResolve(registerDevicesActions.setDeviceId(id));
      } else {
        history.push('/dashboard');
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export function* updateDeviceDetailsSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        registerDevicesActions.updateDevice().type
      );
      yield putResolve(registerDevicesActions.setLoading(true));
      let result = yield call(api, `/api/update-device-details`, 'PUT', data);

      if (result.success === true) {
        let event_properties = {
          pageUrl: `${process.env.REACT_APP_PUBLICURL}/dashboard`,
          pageTitle: 'Dashboard-Page',
          referrerUrl: document.referrer
        };
        yield putResolve(registerDevicesActions.setLoading(false));
        toast.success(result.message, {
          className: 'toast-success',
          style: { width: '25rem' }
        });
        setAmplitudeUserId(data.email);
        sendAmplitudeData(`Updated the Device`, data.eventProperties);
        setTimeout(() => {
          sendAmplitudeData(`Visited the dashboard`, event_properties);
          history.push('/dashboard');
        }, 1500);
      } else {
        yield putResolve(registerDevicesActions.loadError(result.message));
        yield putResolve(registerDevicesActions.setLoading(false));
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default function* devicesSaga() {
  yield spawn(storeDeviceDetailsSaga);
  yield spawn(getSingleDeviceDetailsSaga);
  yield spawn(updateDeviceDetailsSaga);
}
