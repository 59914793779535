//base imports and third party
import React from 'react';
import { connect } from 'react-redux';
import bcrypt from 'bcryptjs';

//services and utils
import { signUpActions, signUpSelector } from './store';
import { state } from '../../store';

//components
import { PasswordInput } from '../passwordInputField/passwordField';
import { ButtonWithLoader } from '../button';

//assets and css
import vidamiLogo from '../../assets/img/New-Vidami-Logo.png';
import { sendAmplitudeData } from '../../utilities/amplitude';
import googleLogo from '../../assets/img/google.png';
import appleLogo from '../../assets/img/apple.png';

export const SignIn = connect(
  state.select({
    credentialError: signUpSelector(state => state.credentialError, ''),
    email: signUpSelector(state => state.email, []),
    password: signUpSelector(state => state.password, []),
    passwordErr: signUpSelector(state => state.passwordErr, []),
    loading: signUpSelector(state => state.loading, false)
  }),
  state.actions(signUpActions, [
    'load',
    'loginUser',
    'handleEmailChange',
    'handlePasswordChange',
    'loadPasswordError',
    'loadCredentialError'
  ])
)(
  class SignIn extends React.Component {
    constructor(props) {
      super(props);
      this.state = { showPassword: false };
    }

    componentDidMount() {
      document.title = 'Sign In | Vidami';
      const errorString = window.location.href.split('?');

      if (errorString.length > 1) {
        this.props.loadCredentialError(
          "You'll have to login with credentials!"
        );
      }
    }
    handleSubmit = async e => {
      e.preventDefault();
      const { email, password } = this.props;
      const hashedPassword = await bcrypt.hash(password, 10);
      this.props.loginUser({
        username: email,
        password: password
      });
    };

    checkPassword = e => {
      if (e.target.value.length >= 8) {
        this.props.loadPasswordError('');
      } else {
        this.props.loadPasswordError(
          'Password must be greater than 8 characters.'
        );
      }
    };

    togglePasswordVisibility = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };

    render() {
      const {
        handleEmailChange,
        handlePasswordChange,
        email,
        loading
      } = this.props;

      return (
        <div className="signup-container">
          <form
            onSubmit={e => {
              this.handleSubmit(e);
            }}
          >
            <div className="text-center">
              <img
                src={vidamiLogo}
                className="register-account-company-logo-style mb-5"
                alt="vidamiLogo"
              />
            </div>
            <p className="registration-page-title">Welcome back!</p>
            <hr />
            <button
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_SERVER_URL}/auth/google`)
              }
              type="button"
              className="login-with-google-btn"
            >
              <img src={googleLogo} alt="" />
              Sign in with Google
            </button>
            <button
              type="button"
              className="login-with-apple-btn"
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_SERVER_URL}/auth/apple`)
              }
            >
              <img src={appleLogo} alt="" />
              Sign in with Apple
            </button>
            <hr className="hr-text mt-2 mb-0" data-content="or" />

            <div className="form-details mt-3">
              <label className="label" htmlFor="email">
                E-mail
              </label>
              <input
                type="email"
                name="email"
                className="input"
                placeholder="Type your e-mail"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <PasswordInput
              label="Password"
              id="Password"
              placeholder={'Type your password'}
              togglePasswordVisibility={this.togglePasswordVisibility}
              checkPassword={e => {
                this.checkPassword(e);
                handlePasswordChange({ field: e, fieldName: 'password' });
              }}
              showPassword={this.state.showPassword}
            />
            {this.props.credentialError.length > 1 && (
              <p className="text-danger" style={{ width: '26rem' }}>
                {this.props.credentialError}
              </p>
            )}
            <ButtonWithLoader buttonText="Sign In" loading={loading} />
            <div className="register-button-link">
              Don't have an account?{' '}
              <a
                href="/"
                onClick={() => {
                  let eventProperties = {
                    pageUrl: process.env.REACT_APP_PUBLICURL + '/',
                    pageTitle: 'Sign-Up Page',
                    referrerUrl: document.referrer
                  };
                  sendAmplitudeData('Visited Sign-Up Page', eventProperties);
                }}
              >
                Register
              </a>
            </div>
            <div
              className="forgot-password-button-link"
              onClick={() => {
                let eventProperties = {
                  pageUrl: process.env.REACT_APP_PUBLICURL + '/reset-password',
                  pageTitle: 'Reset-Password Page',
                  referrerUrl: document.referrer
                };
                sendAmplitudeData(
                  'Visited Reset-Password Page',
                  eventProperties
                );
              }}
            >
              <a href="/reset-password">Forgot password?</a>
            </div>
          </form>
        </div>
      );
    }
  }
);
