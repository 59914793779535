const PATTERNS = {
  vidamiClassic: [/^20VC1[0-9]{4,5}$/i, /^20VC1-[0-9]{4,5}$/i, /^[0-9]{4}VC1[a-zA-Z]{2}[0-9]{4,5}$/i],
  vidamiBlue: [/^21VB1-[0-9]{4,5}$/i, /^[0-9]{4}VB1[0-9]{4,5}$/i]
};

export const isCorrectPattern = (input, modal) => {
  const validPatterns =
    modal === 'Vidami Blue' ? PATTERNS.vidamiBlue : PATTERNS.vidamiClassic;
  return validPatterns.some(pattern => pattern.test(input));
};
