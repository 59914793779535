//base imports and third party
import React, { Component } from 'react';
import { connect } from 'react-redux';

//services and utils
import { state } from '../../store';
import { signUpSelector } from '../authentication/store';
import { registerDevicesActions, registerDevicesSelector } from './store';

//components
import { ButtonWithLoader } from '../button';
import Stepper from '../stepper';

//assets and css
import vidamiSerialNumber from '../../assets/img/four-digits-serial-number.png';
import vidamiBlueSerialNumber from '../../assets/img/vidami-sn-21vb1.png';
import { isIOS } from '../../utilities/helpers';
import { isCorrectPattern } from './helper';

export const RegisterDevice = connect(
  state.select({
    err: signUpSelector(state => state.err, ''),
    user: signUpSelector(state => state.user, {}),
    deviceSerialNumber: registerDevicesSelector(
      state => state.deviceSerialNumber,
      ''
    ),
    errorMessage: registerDevicesSelector(state => state.errorMessage, ''),
    deviceName: registerDevicesSelector(state => state.deviceName, ''),
    loading: registerDevicesSelector(state => state.loading, '')
  }),
  state.actions(registerDevicesActions, [
    'handleDeviceSerialNumberChange',
    'storeDevice',
    'deviceChangeHandler',
    'setError'
  ])
)(
  class RegisterDevice extends Component {
    componentDidMount() {
      document.title = `${this.props.addDevice ? 'Add' : 'Register'
        } Device | Vidami`;
      if (isIOS())
        this.props.deviceChangeHandler({
          deviceName: 'Vidami Blue'
        });
    }
    submit = event => {
      event.preventDefault();
      //props
      const { deviceSerialNumber, user, deviceName, addDevice } = this.props;
      // pattern to check correct sr/no

      if (
        isCorrectPattern(deviceSerialNumber, deviceName) &&
        deviceName.length &&
        user &&
        Object.keys(user).length
      ) {
        this.props.setError(null);
        let eventProperties = {
          pageUrl: window.location.href,
          pageTitle: 'SignUp-Page',
          referrerUrl: document.referrer,
          deviceId: deviceSerialNumber
        };
        this.props.storeDevice({
          userId: user.id,
          serialNumber: deviceSerialNumber,
          name: deviceName,
          email: user.email,
          addDevice: addDevice,
          eventProperties
        });
      }
    };

    render() {
      //props
      const {
        deviceSerialNumber,
        errorMessage,
        deviceName,
        addDevice,
        loading
      } = this.props;
      //actions
      const {
        handleDeviceSerialNumberChange,
        deviceChangeHandler
      } = this.props;
      let buttonText = addDevice ? 'Add' : 'Register Device';
      return (
        <div
          className={
            addDevice
              ? 'registrationAddDevice-container'
              : 'registrationDevice-container'
          }
        >
          {!addDevice && <Stepper activeSection={'Register Device'} />}
          <p className="registration-page-title">
            {addDevice ? 'Add Device' : 'Register your device'}
          </p>
          <form
            className="registerDevice-container mb-5"
            onSubmit={this.submit}
          >
            <div className="serial-number-info-container">
              <ol style={{ paddingLeft: '15px', margin: '0' }}>
                <li className="serial-number-info mt-4">
                  <p>Locate your serial number at the bottom of your device</p>
                </li>
                <li className="serial-number-info mb-0 mt-3">
                  <p>
                    Choose your model from the dropdown below and enter your
                    serial number
                  </p>
                </li>
              </ol>
            </div>
            <div className="form-details mt-4">
              <div className="serial-number-input-wrapper">
                <select
                  className="serial-number-select-list-style"
                  onChange={data => {
                    deviceChangeHandler({
                      deviceName: data.target.value,
                      deviceSerialNumber
                    });
                  }}
                  value={deviceName}
                >
                  <option
                    className="paddle-first-option"
                    disabled
                    value={'DEFAULT'}
                  >
                    Select the Pedal
                  </option>
                  {!isIOS() && <option value="Vidami">Vidami</option>}
                  <option value="Vidami Blue">Vidami Blue</option>
                </select>
                <input
                  name="serialNumber"
                  className="serial-number-input"
                  placeholder="Enter Serial Number"
                  required
                  value={deviceSerialNumber}
                  onChange={e => {
                    handleDeviceSerialNumberChange({
                      deviceSerialNumber: e.target.value,
                      deviceName
                    });
                  }}
                />
              </div>
            </div>
            <div
              style={{
                color: 'red',
                textAlign: 'center',
                height: '1.4rem',
                paddingTop: '0.5rem'
              }}
            >
              {errorMessage.length ? errorMessage : ''}
            </div>
            <ButtonWithLoader buttonText={buttonText} loading={loading} />
            {addDevice ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingTop: '1rem',
                  fontWeight: 500
                }}
              >
                <a href="/dashboard">Cancel</a>
              </div>
            ) : null}
          </form>
        </div>
      );
    }
  }
);
