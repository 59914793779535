import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import {
  signUpActions,
  signUpSelector
} from '../components/authentication/store';
import history from '../components/helper/history';
import { state } from '../store';

const PrivateRoute = connect(
  state.select({
    user: signUpSelector(state => state.user)
  }),
  state.actions(signUpActions, ['loadUserAction'])
)(
  class PrivateRoute extends React.Component {
    componentDidMount() {
      this.props.loadUserAction();
    }
    render() {
      const { component: Component, user, ...rest } = this.props;
      const isLoggedIn = user !== null;
      const isAdmin = rest.requireAdmin;
      const isAuthenticated =
        isLoggedIn && (isAdmin ? user.isAdmin !== null : true);
      const homeUrl = '/';
      return (
        <Route
          {...rest}
          component={props => {
            return isAuthenticated ? (
              <Component {...props} />
            ) : (
              history.push(homeUrl)
            );
          }}
        />
      );
    }
  }
);

export default PrivateRoute;
