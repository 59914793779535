//base imports and third party
import React from 'react';
import { connect } from 'react-redux';
import bcrypt from 'bcryptjs';

//services and utils
import { signUpActions, signUpSelector } from './store';
import { state } from '../../store';

//components
import { ButtonWithLoader } from '../button';

//assets and css
import vidamiLogo from '../../assets/img/New-Vidami-Logo.png';
import { PasswordInput } from '../passwordInputField/passwordField';

export const ConfirmPassword = connect(
  state.select({
    err: signUpSelector(state => state.err, ''),
    email: signUpSelector(state => state.email, ''),
    password: signUpSelector(state => state.password, ''),
    passwordErr: signUpSelector(state => state.passwordErr, ''),
    credentialError: signUpSelector(state => state.credentialError, ''),
    loading: signUpSelector(state => state.loading)
  }),
  state.actions(signUpActions, [
    'load',
    'loginUser',
    'handleEmailChange',
    'handlePasswordChange',
    'changePassword',
    'loadPasswordError',
    'validateToken'
  ])
)(
  class ConfirmPassword extends React.Component {
    constructor(props) {
      super(props);
      this.state = { showPassword: false, showConfirmPassword: false };
    }
    componentDidMount() {
      document.title = 'Reset Password | Vidami';
      this.props.validateToken({ token: this.props.match.params.id });
    }
    handleSubmit = async e => {
      e.preventDefault();

      const { password } = this.props;
      const hashedPassword = await bcrypt.hash(password, 10);

      this.props.changePassword({
        resetTokenId: this.props.match.params.id,
        password: hashedPassword
      });
    };

    togglePasswordVisibility = fieldName => {
      if (fieldName === 'showPassword') {
        this.setState({ showPassword: this.state.showPassword ? false : true });
      } else {
        this.setState({
          showConfirmPassword: this.state.showConfirmPassword ? false : true
        });
      }
    };

    checkPassword = e => {
      if (e.target.value.length >= 8) {
        this.props.loadPasswordError('');
      } else {
        this.props.loadPasswordError(
          'Password must be greater than 8 characters.'
        );
      }
    };

    comparePassword = (passwordField, confirmPasswordField) => {
      if (passwordField !== confirmPasswordField) {
        this.props.loadPasswordError('Password must be same.');
      } else {
        this.props.loadPasswordError('');
      }
    };

    render() {
      const {
        handlePasswordChange,
        password,
        confirmPassword,
        passwordErr,
        credentialError,
        loading
      } = this.props;

      return (
        <div className="password-confirm-container">
          <form
            onSubmit={e => {
              this.handleSubmit(e);
            }}
            className="reset-password-form"
          >
            <div className="text-center mb-5">
              <img
                src={vidamiLogo}
                className="register-account-company-logo-style"
                alt="vidamiLogo"
              />
            </div>
            <p className="registration-page-title">Reset Password</p>

            <PasswordInput
              label="Password"
              placeholder="Enter new password"
              value={password}
              checkPassword={e => {
                this.checkPassword(e);
                handlePasswordChange({ field: e, fieldName: 'password' });
                this.comparePassword(e.target.value, confirmPassword);
              }}
              passwordErr={passwordErr}
              showPassword={this.state.showPassword}
              togglePasswordVisibility={() => {
                this.togglePasswordVisibility('showPassword');
              }}
              isPassword={true}
              required
            ></PasswordInput>
            <PasswordInput
              label="Confirm Password"
              placeholder="Re-enter password"
              value={confirmPassword}
              checkPassword={e => {
                this.checkPassword(e);
                handlePasswordChange({ field: e, fieldName: 'confirmPassword' });
                this.comparePassword(e.target.value, password);
              }}
              showPassword={this.state.showConfirmPassword}
              togglePasswordVisibility={() => {
                this.togglePasswordVisibility('showConfirmPassword');
              }}
              isPassword={true}
              required
            ></PasswordInput>
            {credentialError.length > 0 && (
              <p className="text-danger" style={{ marginTop: '10px' }}>
                {this.props.credentialError}
              </p>
            )}
            <ButtonWithLoader
              buttonText={'Save'}
              loading={loading}
              disabled={
                passwordErr.length > 0 || credentialError.length > 0
                  ? true
                  : false
              }
            />
          </form>
        </div>
      );
    }
  }
);
