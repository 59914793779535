import {
  putResolve,
  take,
  takeLatest,
  call,
  spawn,
  takeEvery
} from 'redux-saga/effects';
import { api } from '../helper/api';
import { adminDashboardActions } from './store';
import { toast } from 'react-toastify';
import {
  sendAmplitudeData,
  setAmplitudeUserId
} from '../../utilities/amplitude';

export function* deviceDetailsSaga() {
  while (true) {
    try {
      yield take(adminDashboardActions.adminLoadDevicesData().type);
      let result = yield call(api, `/api/get-all-devices`, 'GET');
      if (result.data) {
        yield putResolve(
          adminDashboardActions.loadData({ type: 'device', list: result.data })
        );
      } else {
        yield putResolve(
          adminDashboardActions.loadData({ type: 'device', list: [] })
        );
      }
    } catch (err) {
      console.log(err);
      yield putResolve(
        adminDashboardActions.loadData({ type: 'device', list: [] })
      );
    }
  }
}

export function* userDetailsSaga() {
  while (true) {
    try {
      yield take(adminDashboardActions.adminLoadUsersData().type);
      let result = yield call(api, `/api/get-all-users`, 'GET');
      if (result.data) {
        yield putResolve(
          adminDashboardActions.loadData({ type: 'user', list: result.data })
        );
      } else {
        yield putResolve(
          adminDashboardActions.loadData({ type: 'user', list: [] })
        );
      }
    } catch (err) {
      console.log(err);
      yield putResolve(
        adminDashboardActions.loadData({ type: 'user', list: [] })
      );
    }
  }
}

export function* editDeviceSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        adminDashboardActions.adminEditDevice().type
      );
      if (window.confirm('Are you sure you want to update user details?')) {
        let result = yield call(
          api,
          `/api/admin-update-device-details`,
          'PUT',
          data
        );
        if (result.success === true) {
          toast.success(result.message, {
            className: 'toast-success',
            style: { width: '25rem' }
          });
          yield putResolve(
            adminDashboardActions.updateList({ type: 'device', device: data })
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export function* deleteDeviceSaga() {
  while (true) {
    try {
      const { payload: device } = yield take(
        adminDashboardActions.adminDeleteDevice().type
      );
      if (window.confirm('Are you sure you want to delete this device?')) {
        let result = yield call(
          api,
          `/api/admin-delete-device`,
          'DELETE',
          device
        );
        if (result.success === true) {
          toast.success(result.message, {
            className: 'toast-success',
            style: { width: '25rem' }
          });
          yield putResolve(
            adminDashboardActions.deleteDevice({ type: 'device', device })
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export function* setPageSaga() {
  while (true) {
    try {
      const {
        payload: { page, type }
      } = yield take(adminDashboardActions.setAdminPage().type);
      yield putResolve(adminDashboardActions.setPage({ type, page }));
    } catch (err) {
      console.log(err);
    }
  }
}

export default function* adminDashboardSaga() {
  yield spawn(deviceDetailsSaga);
  yield spawn(editDeviceSaga);
  yield spawn(deleteDeviceSaga);
  yield spawn(setPageSaga);
  yield spawn(userDetailsSaga);
}
