import React from 'react';
import { sendAmplitudeData } from '../../utilities/amplitude';
import Stepper from '../stepper';

const ExtensionLinks = ({ linkObjects = [] }) => {
  return linkObjects.map(
    (
      { image, buttonImage, buttonText, installText, extensionLink } = {},
      index
    ) => (
      <div
        className="installSoftware-container"
        key={'extension-link-' + index}
      >
        <Stepper activeSection={'install extension'} />
        <img src={image} style={{ width: '64px' }} alt="" />
        <p className="registration-page-title">{installText}</p>

        <button
          className="registration-button button-vertical-alignment"
          onClick={() => {
            let event_properties = {
              pageUrl: window.location.href,
              pageTitle: 'Installation-Page',
              referrerUrl: document.referrer
            };
            sendAmplitudeData(
              `Clicked the Download extension button`,
              event_properties
            );
            window.open(extensionLink, '_blank');
            let install_event_properties = {
              pageUrl:
                process.env.REACT_APP_PUBLICURL + '/onboarding/step5/completed',
              pageTitle: 'Completed-Onboarding-Page',
              referrerUrl: document.referrer
            };
            sendAmplitudeData(
              `Completed the onboarding workflow`,
              install_event_properties
            );
            window.location.href = `${process.env.REACT_APP_PUBLICURL}/onboarding/step5/completed`;
          }}
        >
          {buttonImage && (
            <img src={buttonImage} style={{ width: '16px' }} alt="" />
          )}
          {buttonText}
        </button>
      </div>
    )
  );
};

export default ExtensionLinks;
