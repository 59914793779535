import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, useEffect, useState } from 'react';
import { FormControl, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { state } from '../../store';
import { signUpSelector } from '../authentication/store';
import { adminDashboardActions, adminDashboardSelector } from './store';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ReactPaginate from 'react-paginate';
import './adminDashboard.css';
import {
  NoDataTableRow,
  TableRow,
  TableRowEdit,
  VidamiModelDropDown
} from './components';
import { Link } from 'react-router-dom';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { downloadExcelSheet } from '../helper';

export const AdminDashBoard = connect(
  state.select({
    err: signUpSelector(state => state.err, ''),
    user: signUpSelector(state => state.user),
    tableState: adminDashboardSelector(state => state.devicesTable, {})
  }),
  state.actions(adminDashboardActions, [
    'adminLoadDevicesData',
    'adminEditDevice',
    'adminDeleteDevice',
    'updateFilter',
    'adminEditDevice',
    'adminDeleteDevice',
    'setAdminPage'
  ])
)(
  class AdminDashBoard extends Component {
    constructor(props) {
      super(props);
      this.state = {
        editIndex: null
      };
      this.handleEdit = this.handleEdit.bind(this);
      this.handleSave = this.handleSave.bind(this);
      this.handleModelChange = this.handleModelChange.bind(this);
      this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
      this.handleDelete = this.handleDelete.bind(this);
      this.handlePageClick = this.handlePageClick.bind(this);
    }
    componentDidMount() {
      document.title = 'Admin Dashboard | Vidami';
      if (!this.props.tableState.hasLoadedData)
        this.props.adminLoadDevicesData();
    }
    handleEdit = device => {
      this.setState({ editIndex: device.id });
    };
    handleSave = device => {
      this.props.adminEditDevice(device);
      this.setState({ editIndex: null });
    };
    handleDelete = device => {
      this.props.adminDeleteDevice(device);
      this.setState({ editIndex: null });
    };
    handleClose = () => {
      this.setState({ editIndex: null });
    };
    handleModelChange = model => {
      const filter = { ...this.props.tableState.filter };
      filter.model = model;
      this.props.updateFilter(filter);
    };
    handleSearchTermChange = e => {
      const searchTerm = e.target.value;
      const filter = { ...this.props.tableState.filter };
      filter.searchTerm = searchTerm;
      this.props.updateFilter(filter);
    };
    handlePageClick = e => {
      this.props.setAdminPage({ type: 'device', page: e.selected });
    };
    handleDownload = data => {
      const columnNameProp = [
        { label: 'Email', value: 'user.email' },
        { label: 'Name', value: 'user.name' },
        { label: 'Serial Number', value: 'serialNumber' },
        { label: 'Registered On', value: 'createdAt' },
        { label: 'Modified On', value: 'updatedAt' }
      ];

      downloadExcelSheet(
        "Vidami User's Devices List",
        columnNameProp,
        'Devices',
        data
      );
    };

    render() {
      const {
        filteredList,
        filter,
        listChunks,
        paginationState: { pageCount, activePage },
        hasLoadedData
      } = this.props.tableState;

      return hasLoadedData ? (
        <div className="main-container">
          <div className="filter-container">
            <div style={{ fontSize: '20px', marginRight: 'auto' }}>
              All Devices
            </div>
            <button
              onClick={() => this.handleDownload(filteredList)}
              style={{
                backgroundColor: '#0e539f',
                border: 'none',
                color: 'white',
                padding: '7px 20px',
                textAlign: 'center',
                display: 'inline-block',
                fontSize: '16px',
                cursor: 'pointer',
                borderRadius: '6px',
                borderColor: '#5a8dee'
              }}
            >
              Download
              <FontAwesomeIcon
                icon={faDownload}
                style={{ marginLeft: '8px' }}
              />
            </button>
            <FormControl
              placeholder="Search"
              aria-label="Search"
              style={{ width: '30%', margin: '0 10px' }}
              value={filter.searchTerm}
              onChange={this.handleSearchTermChange}
            />
            <VidamiModelDropDown
              modelName={filter.model}
              onChange={this.handleModelChange}
              isEmpty
            />
          </div>
          <div className="table-container">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th width="5%">#</th>
                  <th>User</th>
                  <th width="14%">Email Id</th>
                  <th width="14%">SerialNumber</th>
                  <th width="14%">Model</th>
                  <th width="14%">Logged In With</th>
                  <th width="14%">Modified On</th>
                  <th width="10%">Actions</th>
                </tr>
              </thead>
              <tbody>
                {listChunks.length ? (
                  listChunks[activePage].map((data, i) => {
                    const editMode = this.state.editIndex === data.id;
                    return (
                      <tr
                        key={data.id}
                        className={editMode ? 'edit_mode' : ''}
                        style={{ height: '20px' }}
                      >
                        {editMode ? (
                          <TableRowEdit
                            data={data}
                            index={data.sn}
                            onSave={this.handleSave}
                            onClose={this.handleClose}
                          />
                        ) : (
                          <TableRow
                            data={data}
                            index={data.sn}
                            onEdit={() => this.handleEdit(data)}
                            onDelete={() => this.handleDelete(data)}
                          />
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <NoDataTableRow />
                )}
              </tbody>
            </Table>
          </div>
          <div className="table-footer">
            <div style={{ marginRight: 'auto' }}>
              <Link to="/admin-dashboard/users">See Users List</Link>
            </div>
            <div style={{ margin: '10px' }}>
              {filteredList.length} Result(s)
            </div>
            {pageCount > 0 && (
              <ReactPaginate
                className="react-pagination"
                breakLabel="..."
                nextLabel="next >"
                onPageChange={this.handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
              />
            )}
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      );
    }
  }
);
