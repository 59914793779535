import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, useEffect, useState } from 'react';
import { FormControl, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { state } from '../../../store';
import { signUpSelector } from '../../authentication/store';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ReactPaginate from 'react-paginate';
import '../adminDashboard.css';
import {
  NoDataTableRow,
  TableRow,
  TableRowEdit,
  TableRowUsers,
  VidamiModelDropDown
} from '../components';
import { adminDashboardUserActions, adminDashboardUserSelector } from './store';
import { adminDashboardSelector } from '../store';
import { Link } from 'react-router-dom';

export const AdminDashBoardUserDetail = connect(
  state.select({
    err: signUpSelector(state => state.err, ''),
    user: adminDashboardUserSelector(state => state.user, {}),
    tableState: adminDashboardUserSelector(state => state.tableState, {
      deviceList: []
    })
  }),
  state.actions(adminDashboardUserActions, [
    'adminLoadUserDevices',
    'adminLoadDevicesData',
    'adminEditUserDevice',
    'adminDeleteUserDevice',
    'updateFilter',
    'adminEditDevice',
    'adminDeleteDevice',
    'setAdminPage',
    'adminLoadUserData'
  ])
)(
  class AdminDashBoardUserDetail extends Component {
    constructor(props) {
      super(props);
      this.state = {
        editIndex: null
      };
      this.handleEdit = this.handleEdit.bind(this);
      this.handleSave = this.handleSave.bind(this);
      this.handleDelete = this.handleDelete.bind(this);
    }
    handleEdit = device => {
      this.setState({ editIndex: device.id });
    };
    handleSave = device => {
      this.props.adminEditUserDevice(device);
      this.setState({ editIndex: null });
    };
    handleDelete = device => {
      this.props.adminDeleteUserDevice(device);
      this.setState({ editIndex: null });
    };
    handleClose = () => {
      this.setState({ editIndex: null });
    };
    componentDidMount() {
      document.title = 'Admin Dashboard | Vidami';
      this.props.adminLoadUserData(this.props.match.params.id);
    }
    render() {
      const { name, email, provider, createdAt } = this.props.user;
      const { deviceList } = this.props.tableState;
      return (
        <div className="main-container">
          <div>Name:{name}</div>
          <div>Email:{email}</div>
          <div>Provider:{provider}</div>
          <div>Registered On:{createdAt}</div>
          <div className="filter-container">
            <div style={{ fontSize: '20px', marginRight: 'auto' }}>Devices</div>
          </div>
          <div className="table-container">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th width="5%">#</th>
                  <th width="14%">SerialNumber</th>
                  <th width="14%">Model</th>
                  <th width="14%">Modified On</th>
                  <th width="10%">Actions</th>
                </tr>
              </thead>
              <tbody>
                {deviceList.length ? (
                  deviceList.map((data, i) => {
                    const editMode = this.state.editIndex === data.id;
                    return (
                      <tr
                        key={data.id}
                        className={editMode ? 'edit_mode' : ''}
                        style={{ height: '20px' }}
                      >
                        {editMode ? (
                          <TableRowEdit
                            data={data}
                            index={i + 1}
                            showUserDetails={false}
                            onSave={this.handleSave}
                            onClose={this.handleClose}
                          />
                        ) : (
                          <TableRow
                            data={data}
                            index={i + 1}
                            showUserDetails={false}
                            onEdit={() => this.handleEdit(data)}
                            onDelete={() => this.handleDelete(data)}
                          />
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <NoDataTableRow />
                )}
              </tbody>
            </Table>
          </div>
        </div>
      );
    }
  }
);
