//base imports and third party
import React from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

//services and utils
import history from '../helper/history';

//components

//assets and css
import verticalDots from '../../assets/img/menu-vertical.png';
import safariLogo from '../../assets/img/safari.png';
import chromeLogo from '../../assets/img/chrome.png';
import vidamiLogo from '../../assets/img/vidami-logo-normal.png';

export default class BrowserContainer extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onInstall = this.onInstall.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  onInstall() {
    this.props.onInstall();
  }

  render() {
    return (
      <div className="col-md-6 text-center mb-4">
        <div className="device-details software-device-container device-details-card-height">
          <div className="dropdown-menu-style">
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle>
                <img src={verticalDots} alt="verticalDots" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={this.onInstall}>
                  Install Software
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="extension-device-container" onClick={this.onInstall}>
            <div>
              <img
                src={vidamiLogo}
                alt={'Vidami Logo'}
                className="extension-device-style"
              />
              <p className="software-name">Vidami Mobile for Safari</p>
            </div>
            <div>
              <img
                src={safariLogo}
                alt={'Safari Logo'}
                className="extension-device-style"
              />
              <p className="software-name">Vidami Safari Desktop Extension</p>
            </div>
            <div>
              <img
                src={chromeLogo}
                alt={'Chrome Logo'}
                className="extension-device-style"
              />
              <p className="software-name">Vidami Chrome Desktop Extension</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
