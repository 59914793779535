//base imports and third party
import React, { createRef } from 'react';
import { connect } from 'react-redux';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Collapse,
  NavbarToggler
} from 'reactstrap';
import OutsideClickHandler from 'react-outside-click-handler';
import ResizeObserver from 'resize-observer-polyfill';

//services and utils
import { state } from '../store';
import { signUpActions, signUpSelector } from './authentication/store';

//components

//assets and css
import companyLogo from '../assets/img/New-Vidami-Logo.png';
import defaultUserLogo from '../assets/img/default-user.png';
import caretIcon from '../assets/img/caret.png';
import { sendAmplitudeData, setAmplitudeUserId } from '../utilities/amplitude';
import { sendVidamiMessage } from '../components/helper';
import { Link } from 'react-router-dom';

export const Header = connect(
  state.select({
    err: signUpSelector(state => state.err, '')
  }),
  state.actions(signUpActions, ['loadUserAction', 'getAllMembers'])
)(
  class Header extends React.Component {
    ro;
    width;

    widgetElement = createRef();
    constructor(props) {
      super(props);

      this.toggle = this.toggle.bind(this);
      this.showDropDown = this.showDropDown.bind(this);
      this.state = {
        isOpen: false,
        dropdownOpen: false,
        widgetWidth: 0
      };
    }

    componentDidMount() {
      this.props.loadUserAction();
      const widget = this.widgetElement.current;
      this.ro = new ResizeObserver(
        ([
          {
            contentRect: { width }
          }
        ]) => {
          this.width = width;
          this.setState({ widgetWidth: width });
        }
      );
      this.ro.observe(widget);
    }

    componentWillUnmount() {
      this.ro.disconnect();
    }

    toggle() {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }

    showDropDown = event => {
      this.setState({
        dropdownOpen: !this.state.dropdownOpen
      });
    };

    isAdminPage = window.location.pathname.includes('/admin-dashboard');

    render() {
      const { user } = this.props;
      const userName =
        user.displayName || user.name || user._json
          ? user.displayName || user.name || user._json.email
          : '';
      const userEmail = user.email;
      return (
        <div ref={this.widgetElement}>
          <Navbar
            light
            expand="lg"
            className="navbar-conatiner-style p-3 pl-5 pr-5"
          >
            <NavbarBrand className="pl-1" href="/dashboard">
              <img src={companyLogo} className="company-logo-style" alt="" />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar className="pl-0 pr-0">
              <Nav navbar className="mr-auto align-items-center">
                <NavItem className="navitem-small-screen-style">
                  {!user.isAdmin ? (
                    <Link
                      className="nav-list-item mr-0 mr-md-5"
                      to="/dashboard"
                    >
                      Dashboard
                    </Link>
                  ) : (
                    <>
                      <Link
                        className="nav-list-item mr-0 mr-md-5"
                        to="/dashboard"
                      >
                        User Dashboard
                      </Link>{' '}
                      <Link
                        className="nav-list-item mr-0 mr-md-5"
                        to="/admin-dashboard"
                      >
                        Admin Dashboard
                      </Link>
                    </>
                  )}
                </NavItem>
                <NavItem className="navitem-small-screen-style">
                  {this.state.widgetWidth >= 991 ? (
                    <NavLink className="nav-list-item">
                      <div
                        className="d-flex align-items-center logout-container cursor-pointer"
                        onClick={event => this.showDropDown(event)}
                      >
                        <img
                          className="mr-2 user-avatar"
                          src={user.avatar ? user.avatar : defaultUserLogo}
                          alt=""
                        />
                        <div>
                          <p className="mb-0 user-name">{user.name}</p>
                          <p className="mb-0 user-email" title={user.email}>
                            {user.email}
                          </p>
                        </div>
                        <div>
                          <img
                            src={caretIcon}
                            style={{ width: '20px', marginLeft: '8px' }}
                            alt=""
                          />
                        </div>
                        {this.state.dropdownOpen && (
                          <OutsideClickHandler
                            onOutsideClick={() => {
                              this.showDropDown();
                            }}
                          >
                            <div className="logout-wrapper">
                              <div
                                className="logout-wrapper-item cursor-pointer"
                                onClick={() => {
                                  localStorage.setItem('login', false);
                                  localStorage.removeItem('jwtToken');
                                  setAmplitudeUserId(userEmail);
                                  let pageName = window.location.pathname.split(
                                    '/'
                                  );
                                  let eventProperties = {
                                    pageUrl: window.location.href,
                                    pageTitle: pageName[pageName.length - 1],
                                    referrerUrl: document.referrer
                                  };
                                  sendAmplitudeData(
                                    'Logged out',
                                    eventProperties
                                  );
                                  window.location.href = `${process.env.REACT_APP_SERVER_URL}`;
                                }}
                              >
                                Logout
                              </div>
                            </div>
                          </OutsideClickHandler>
                        )}
                      </div>
                    </NavLink>
                  ) : (
                    <NavLink className="nav-list-item cursor-pointer">
                      <div
                        className="logout-container"
                        onClick={e => this.showDropDown(e)}
                      >
                        <div className="cursor-pointer" title={userName}>
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              sendVidamiMessage(null);
                              localStorage.setItem('login', false);
                              localStorage.removeItem('jwtToken');
                              setAmplitudeUserId(userEmail);
                              let pageName = window.location.pathname.split(
                                '/'
                              );
                              let eventProperties = {
                                pageUrl: window.location.href,
                                pageTitle: pageName[pageName.length - 1],
                                referrerUrl: document.referrer
                              };
                              sendAmplitudeData('Logged out', eventProperties);
                              window.location.href = `${process.env.REACT_APP_SERVER_URL}`;
                            }}
                          >
                            Logout
                          </span>
                        </div>
                      </div>
                    </NavLink>
                  )}
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      );
    }
  }
);
