import { putResolve, take, call, spawn } from 'redux-saga/effects';
import { api } from '../helper/api';
import { dashboardActions } from './store';
import { toast } from 'react-toastify';
import {
  sendAmplitudeData,
  setAmplitudeUserId
} from '../../utilities/amplitude';

export function* deviceDetailsSaga() {
  while (true) {
    try {
      yield take(dashboardActions.loadDevicesData().type);
      let result = yield call(api, `/api/device-details`, 'GET');
      if (result.data) {
        yield putResolve(dashboardActions.loadData(result.data));
      } else {
        yield putResolve(dashboardActions.loadData([]));
      }
    } catch (err) {
      console.log(err);
      yield putResolve(dashboardActions.loadData([]));
    }
  }
}

export function* deleteDeviceSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        dashboardActions.deleteDevice().type
      );
      const { id } = data;

      const result = yield call(api, `/api/delete-device`, 'DELETE', { id });
      const user = yield call(api, '/api/user');
      if (result.success === true) {
        let apiResult = yield call(api, `/api/device-details`, 'GET');
        toast.success(result.message, {
          className: 'toast-success',
          style: { width: '21rem' }
        });
        let event_properties = {
          pageUrl: window.location.href,
          pageTitle: 'Dashboard',
          referrerUrl: document.referrer,
          deviceId: id
        };
        setAmplitudeUserId(user.data.email);
        sendAmplitudeData(`Deleted the Device`, event_properties);
        yield putResolve(dashboardActions.loadData(apiResult.data));
      } else {
        toast.error('Something went wrong.', {
          className: 'toast-error'
        });
        yield putResolve('Something went wrong.');
      }
    } catch (error) {
      console.log(error);
      yield putResolve('Something went wrong.');
    }
  }
}

export default function* dashboardSaga() {
  yield spawn(deviceDetailsSaga);
  yield spawn(deleteDeviceSaga);
}
