//base imports and third party
import React from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

//services and utils
import history from '../helper/history';

//components

//assets and css
import verticalDots from '../../assets/img/menu-vertical.png';
import deviceDetails from '../../assets/img/vidami-logo-new-tif.png';
import deviceBlue from '../../assets/img/vidami-logo-tif.png';
export default class DeviceContainer extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {
    const { data, deleteDevice } = this.props;
    return (
      <div className="col-md-6 text-center mb-4">
        <div className="device-details">
          <div className="dropdown-menu-style">
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle>
                <img src={verticalDots} alt="" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={e => {
                    history.push(`/edit-device/${data.id}`);
                  }}
                >
                  Edit
                </DropdownItem>
                <DropdownItem
                  onClick={e => {
                    const msg = 'Are you sure you want to delete this device?';
                    // eslint-disable-next-line no-restricted-globals
                    if (confirm(msg)) {
                      const payload = {
                        id: data.id
                      };
                      deleteDevice(payload);
                    }
                  }}
                >
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="device-img-text">
            <img
              src={
                data.name.includes('Vidami Blue') ? deviceBlue : deviceDetails
              }
              alt={'DeviceImage'}
              className="device-img-style"
            />
            <div className="device-info-wrapper">
              <p className="m-0 device-name">{data.name}</p>
              <p className="serial-number">{data.serialNumber}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
