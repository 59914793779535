import { state } from '../../store';

const defaultFilter = { type: 'device', searchTerm: '', model: 'DEFAULT' };
const defaultUsersFilter = { type: 'user', searchTerm: '' };

const initialTableState = {
  allEntries: [],
  listChunks: [[]],
  filteredList: [],
  filter: defaultFilter,
  paginationState: {
    activePage: 0,
    pageCount: 1,
    paginationChunk: 50
  },
  hasLoadedData: false
};

const getListChunks = (list, chuckSize) => {
  let chunks = [];
  let newList = [...list];
  while (newList.length) {
    chunks.push(newList.splice(0, chuckSize));
  }
  return chunks;
};

const getFilteredListForDevices = (list, filter) => {
  let { searchTerm, model } = filter;
  return list.filter(device => {
    const {
      name,
      user: { name: username, email, updatedAt, provider },
      serialNumber
    } = device;

    const dateObj = new Date(updatedAt);
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();

    const formattedDate = `${month}-${day}-${year}`;

    searchTerm = searchTerm.toLowerCase();
    const hasCorrectModel = model === 'DEFAULT' ? true : model === name;
    const hasMatchingText = searchTerm
      ? name.toLowerCase().includes(searchTerm) ||
        username.toLowerCase().includes(searchTerm) ||
        email.toLowerCase().includes(searchTerm) ||
        serialNumber.toLowerCase().includes(searchTerm) ||
        provider.toLowerCase().includes(searchTerm) ||
        formattedDate.includes(searchTerm)
      : true;
    return hasCorrectModel && hasMatchingText;
  });
};

const getFilteredListForUsers = (list, filter) => {
  let { searchTerm } = filter;
  return list.filter(user => {
    const { name, email, createdAt, provider } = user;

    const dateObj = new Date(createdAt);
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();

    const formattedDate = `${month}-${day}-${year}`;

    searchTerm = searchTerm.toLowerCase();
    const hasMatchingText = searchTerm
      ? name.toLowerCase().includes(searchTerm) ||
        email.toLowerCase().includes(searchTerm) ||
        provider.toLowerCase().includes(searchTerm) ||
        formattedDate.includes(searchTerm)
      : true;
    return hasMatchingText;
  });
};

const getFilteredList = (tableState, allEntries) => {
  let { filter } = tableState;
  let { type } = filter;
  let filteredList =
    type === 'device'
      ? getFilteredListForDevices(allEntries, filter)
      : getFilteredListForUsers(allEntries, filter);
  filteredList = filteredList.sort((a, b) =>
    new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
  );
  [...filteredList].reverse().map((e, i) => {
    e.sn = i + 1;
  });
  return filteredList;
};

const getPaginationCount = (filteredList, paginationChunk = 50) => {
  const count = Math.ceil(filteredList.length / paginationChunk);
  return count;
};

const getTableFromType = (type, state) => {
  switch (type) {
    case 'device':
      state.devicesTable = { ...state.devicesTable };
      return state.devicesTable;
    case 'user':
      state.usersTable = { ...state.usersTable };
      return state.usersTable;
  }
};

const createTableView = tableState => {
  const {
    allEntries,
    paginationState: { paginationChunk }
  } = tableState;
  tableState.filteredList = getFilteredList(tableState, allEntries);
  tableState.listChunks = getListChunks(
    tableState.filteredList,
    paginationChunk
  );
  tableState.paginationState.pageCount = getPaginationCount(
    tableState.filteredList,
    paginationChunk
  );
  return { ...tableState };
};

export const [
  adminDashboardActions,
  adminDashboardSelector,
  adminDashboardState
] = state('adminDashboard', {
  initial: {
    devicesTable: initialTableState,
    usersTable: initialTableState,
    userDevicesTable: initialTableState
  },
  on: {
    loadData: (_, data) => {
      let newStoreObj = { ..._ };
      let tableState = getTableFromType(data.type, newStoreObj);
      if (data.type === 'user') {
        tableState.filter = defaultUsersFilter;
      }
      tableState.hasLoadedData = true;
      tableState.allEntries = data.list;
      tableState.filter.type = data.type;
      tableState = createTableView(tableState);
      return { ...newStoreObj };
    },

    updateList: (_, data) => {
      let newStoreObj = { ..._ };
      let tableState = getTableFromType(data.type, newStoreObj);
      const allEntries = tableState.allEntries;
      const foundIndex = allEntries.findIndex(
        device => device.id === data.device.id
      );
      allEntries.splice(foundIndex, 1, data.device);

      tableState.allEntries = [...allEntries];
      tableState = createTableView(tableState);

      return { ...newStoreObj };
    },

    updateFilter: (_, data) => {
      let newStoreObj = { ..._ };
      let tableState = getTableFromType(data.type, newStoreObj);

      tableState.filter = data;
      tableState = createTableView(tableState);

      return { ...newStoreObj };
    },

    deleteDevice: (_, data) => {
      let newStoreObj = { ..._ };
      let tableState = getTableFromType(data.type, newStoreObj);
      const allEntries = tableState.allEntries;
      const foundIndex = allEntries.findIndex(
        device => device.id === data.device.id
      );
      allEntries.splice(foundIndex, 1);

      tableState.allEntries = [...allEntries];
      tableState = createTableView(tableState);

      return { ...newStoreObj };
    },

    setPage: (_, data) => {
      let newStoreObj = { ..._ };
      let tableState = getTableFromType(data.type, newStoreObj);

      const { paginationState } = tableState;
      paginationState.activePage = data.page;

      return { ...newStoreObj };
    }
  },
  events: [
    'adminLoadDevicesData',
    'adminLoadUsersData',
    'adminEditDevice',
    'adminDeleteDevice',
    'setAdminPage'
  ]
});
