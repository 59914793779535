//base imports and third party
import React from 'react';
import { connect } from 'react-redux';

//services and utils
import history from '../helper/history';
import { signUpActions, signUpSelector } from './store';
import { state } from '../../store';

//assets and css
import vidamiLogo from '../../assets/img/New-Vidami-Logo.png';

export const ErrorPage = connect(
  state.select({
    credentialError: signUpSelector(state => state.credentialError, [])
  }),
  state.actions(signUpActions, [
    'load',
    'loginUser',
    'handleEmailChange',
    'handlePasswordChange',
    'loadPasswordError',
    'loadCredentialError'
  ])
)(
  class ErrorPage extends React.Component {
    componentDidMount() {
      document.title = 'Error | Vidami';
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if (params.error && params.error === 'oauth_error') {
        this.props.loadCredentialError(
          'You originally registered using Google Sign-In, please use those credentials to Log In.'
        );
      } else if (params.error && params.error === 'local_error') {
        this.props.loadCredentialError(
          'You originally registered using Email & Password, please use those credentials to Log In.'
        );
      } else if (params.error && params.error === 'register_error') {
        this.props.loadCredentialError(
          'You originally registered using Email & Password, please proceed to Log In.'
        );
      } else if (params.error && params.error === 'apple_error') {
        this.props.loadCredentialError(
          'You originally registered using Apple Sign-In, please use those credentials to Log In.'
        );
      }
    }

    render() {
      return (
        <div className="errorpage-container">
          <div className="text-center">
            <img
              src={vidamiLogo}
              className="register-account-company-logo-style mb-5"
              alt="vidamiLogo"
            />
          </div>
          <p className="login-error-text">Login Error</p>
          <p className="errorpage-account-creation-link">
            {this.props.credentialError}
          </p>
          <button
            className="errorpage-button"
            onClick={() => history.push('/signin')}
          >
            Back to login
          </button>
        </div>
      );
    }
  }
);
