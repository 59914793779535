//base imports and third party
import React, { Component } from 'react';
export default class Stepper extends Component {
  pages = [
    'welcome page',
    'sign up',
    'Register Device',
    'install extension',
    'completed',
  ];
  render() {
    const { activeSection } = this.props;

    let pageIndex = this.pages.findIndex((page) => {
      return page === activeSection;
    });

    return (
      <>
        <div className="stepper-conatiner">
          <ul className="timeline">
            {this.pages.map((el, index) => {
              return (
                <li
                  className={
                    pageIndex === index
                      ? 'active-tl'
                      : index < pageIndex
                        ? 'fill-item'
                        : ''
                  }
                  key={index}
                >
                  {el}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="show-small-screen mb-4">
          <div className="text-center">Step {pageIndex + 1} of 5</div>
          <div className="small-screen-stepper">
            <span className="small-screen-menu">{this.pages[pageIndex]}</span>
          </div>
        </div>
      </>
    );
  }
}
