import React, { Component, useEffect, useState } from 'react';
import { FormControl, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { state } from '../../store';
import { signUpSelector } from '../authentication/store';
import { adminDashboardActions, adminDashboardSelector } from './store';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ReactPaginate from 'react-paginate';
import './adminDashboard.css';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  NoDataTableRow,
  TableRow,
  TableRowEdit,
  TableRowUsers,
  VidamiModelDropDown
} from './components';
import history from '../helper/history';
import { Link } from 'react-router-dom';
import { downloadExcelSheet } from '../helper';

export const AdminDashBoardUsers = connect(
  state.select({
    err: signUpSelector(state => state.err, ''),
    user: signUpSelector(state => state.user),
    tableState: adminDashboardSelector(state => state.usersTable, {})
  }),
  state.actions(adminDashboardActions, [
    'adminLoadDevicesData',
    'adminEditDevice',
    'adminDeleteDevice',
    'updateFilter',
    'adminEditDevice',
    'adminDeleteDevice',
    'setAdminPage',
    'adminLoadUsersData'
  ])
)(
  class AdminDashBoardUsers extends Component {
    constructor(props) {
      super(props);
      this.state = {
        editIndex: null
      };
      this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
      this.handlePageClick = this.handlePageClick.bind(this);
    }
    componentDidMount() {
      document.title = 'Admin Dashboard | Vidami';
      if (!this.props.tableState.hasLoadedData) this.props.adminLoadUsersData();
    }
    handleSearchTermChange = e => {
      const searchTerm = e.target.value;
      const filter = { ...this.props.tableState.filter };
      filter.searchTerm = searchTerm;
      this.props.updateFilter(filter);
    };
    handlePageClick = e => {
      this.props.setAdminPage({ type: 'user', page: e.selected });
    };

    handleDownload = data => {
      const columnNameProp = [
        { label: 'Email', value: 'email' },
        { label: 'Name', value: 'name' },
        { label: 'Login Provider', value: 'provider' },
        { label: 'Extensions Installed', value: 'isExtensionInstalled' },
        { label: 'Is Admin', value: 'isAdmin' },
        { label: 'Registered On', value: 'createdAt' }
      ];

      downloadExcelSheet(
        'Vidami Onboarding Website Users',
        columnNameProp,
        'Users',
        data
      );
    };

    render() {
      const {
        filteredList,
        listChunks,
        paginationState: { pageCount, activePage },
        hasLoadedData,
        filter
      } = this.props.tableState;

      return hasLoadedData ? (
        <div className="main-container">
          <div className="filter-container">
            <div style={{ fontSize: '20px', marginRight: 'auto' }}>
              All Users
            </div>
            <button
              onClick={() => this.handleDownload(filteredList)}
              style={{
                backgroundColor: '#0e539f',
                border: 'none',
                color: 'white',
                padding: '7px 20px',
                textAlign: 'center',
                display: 'inline-block',
                fontSize: '16px',
                cursor: 'pointer',
                borderRadius: '6px',
                borderColor: '#5a8dee'
              }}
            >
              Download
              <FontAwesomeIcon
                icon={faDownload}
                style={{ marginLeft: '8px' }}
              />
            </button>
            <FormControl
              placeholder="Search"
              aria-label="Search"
              style={{ width: '30%', margin: '0 10px' }}
              value={filter.searchTerm}
              onChange={this.handleSearchTermChange}
            />
          </div>
          <div className="table-container">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th width="5%">#</th>
                  <th>Name</th>
                  <th width="14%">Email Id</th>
                  <th width="14%">Logged In With</th>
                  <th width="14%">Registered On</th>
                </tr>
              </thead>
              <tbody>
                {listChunks.length ? (
                  listChunks[activePage].map((data, i) => {
                    const editMode = this.state.editIndex === data.id;
                    return (
                      <tr
                        key={data.id}
                        className={editMode ? 'edit_mode' : ''}
                        style={{ height: '20px', cursor: 'pointer' }}
                      >
                        <TableRowUsers data={data} index={data.sn} />
                      </tr>
                    );
                  })
                ) : (
                  <NoDataTableRow />
                )}
              </tbody>
            </Table>
          </div>
          <div className="table-footer">
            <div style={{ marginRight: 'auto' }}>
              <Link to="/admin-dashboard">See Devices List</Link>
            </div>
            <div style={{ margin: '10px' }}>
              {filteredList.length} Result(s)
            </div>
            {pageCount > 0 && (
              <ReactPaginate
                className="react-pagination"
                breakLabel="..."
                nextLabel="next >"
                onPageChange={this.handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
              />
            )}
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      );
    }
  }
);
