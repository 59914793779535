//base imports and third party
import React from 'react';
import bcrypt from 'bcryptjs';
import { connect } from 'react-redux';

//services and utils
import { signUpActions, signUpSelector } from './store';
import { state } from '../../store';

//components
import Stepper from '../stepper';
import { ButtonWithLoader } from '../button';
import { PasswordInput } from '../passwordInputField/passwordField';
import { sendAmplitudeData } from '../../utilities/amplitude';

//assets
import googleLogo from '../../assets/img/google.png';
import appleLogo from '../../assets/img/apple.png';
import vidamiLogo from '../../assets/img/New-Vidami-Logo.png';

export const Registration = connect(
  state.select({
    err: signUpSelector(state => state.err, []),
    passwordErr: signUpSelector(state => state.passwordErr, []),
    user: signUpSelector(state => state.user, []),
    name: signUpSelector(state => state.name, []),
    email: signUpSelector(state => state.email, []),
    password: signUpSelector(state => state.password, []),
    confirmPassword: signUpSelector(state => state.confirmPassword, []),
    loading: signUpSelector(state => state.loading, false),
    credentialError: signUpSelector(state => state.credentialError, '')
  }),
  state.actions(signUpActions, [
    'handleNameChange',
    'handlePasswordChange',
    'handleEmailChange',
    'loadPasswordError',
    'signUpUser',
    'loadError'
  ])
)(
  class Registration extends React.Component {
    constructor(props) {
      super(props);
      this.state = { showPassword: false, showConfirmPassword: false };
    }
    componentDidMount() {
      document.title = 'Register | Vidami';
    }
    handleSubmit = async e => {
      e.preventDefault();
      const { email, password, name, passwordErr } = this.props;
      const hashedPassword = await bcrypt.hash(password, 10);
      let eventProperties = {
        pageUrl: window.location.href,
        pageTitle: 'SignUp-Page',
        referrerUrl: document.referrer
      };
      if (passwordErr.length < 1) {
        this.props.signUpUser({
          username: email,
          password: hashedPassword,
          name,
          eventProperties
        });
      }
    };

    togglePasswordVisibility = fieldName => {
      if (fieldName === 'showPassword') {
        this.setState({ showPassword: this.state.showPassword ? false : true });
      } else {
        this.setState({
          showConfirmPassword: this.state.showConfirmPassword ? false : true
        });
      }
    };

    checkPassword = e => {
      if (e.target.value.length >= 8) {
        this.props.loadPasswordError('');
      } else {
        this.props.loadPasswordError(
          'Password must be greater than 8 characters.'
        );
      }
    };

    comparePassword = (passwordField, confirmPasswordField) => {
      if (passwordField !== confirmPasswordField) {
        this.props.loadPasswordError('Password must be same.');
      } else {
        this.props.loadPasswordError('');
      }
    };

    render() {
      const {
        handleEmailChange,
        handlePasswordChange,
        handleNameChange,
        email,
        password,
        confirmPassword,
        name,
        passwordErr,
        loading
      } = this.props;
      const source = new URL(window.location.href).searchParams.get('source');
      return (
        <div className="registration-container">
          <img
            src={vidamiLogo}
            className="register-account-company-logo-style"
            alt="vidamiLogo"
          />
          <Stepper activeSection={'sign up'} />
          <form
            className="registration-form"
            onSubmit={e => {
              this.handleSubmit(e);
            }}
          >
            <p className="registration-page-title">
              Create Your Vidami Account
            </p>
            <p className="registration-page-subTitle">It’s free and easy</p>
            <hr />
            <button
              onClick={() =>
                (window.location.href = `${
                  process.env.REACT_APP_SERVER_URL
                }/auth/google${source ? `?source=${source}` : ''}`)
              }
              type="button"
              className="login-with-google-btn"
            >
              <img src={googleLogo} alt="" />
              Register with Google
            </button>
            <button
              type="button"
              className="login-with-apple-btn"
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_SERVER_URL}/auth/apple`)
              }
            >
              <img src={appleLogo} alt="" />
              Register with Apple
            </button>
            <hr className="hr-text mt-3 mb-0" data-content="or" />
            <div className="form-details mt-3">
              <label className="label" htmlFor="fullname">
                Full Name
              </label>
              <input
                type="text"
                name="fullname"
                className="input"
                placeholder="Enter your name"
                value={name}
                onChange={handleNameChange}
                required
              ></input>
            </div>
            <div className="form-details">
              <label className="label" htmlFor="email">
                E-mail
              </label>
              <input
                type="email"
                name="email"
                className="input"
                placeholder="Type your e-mail"
                value={email}
                onChange={handleEmailChange}
                required
              ></input>
            </div>
            <PasswordInput
              label="Create password"
              placeholder="Create password"
              value={password}
              checkPassword={e => {
                this.checkPassword(e);
                handlePasswordChange({ field: e, fieldName: 'password' });
                this.comparePassword(e.target.value, confirmPassword);
              }}
              passwordErr={passwordErr}
              showPassword={this.state.showPassword}
              togglePasswordVisibility={() => {
                this.togglePasswordVisibility('showPassword');
              }}
              isPassword={true}
              required
            ></PasswordInput>
            <PasswordInput
              label="Confirm password"
              placeholder="Confirm password"
              value={confirmPassword}
              checkPassword={e => {
                handlePasswordChange({
                  field: e,
                  fieldName: 'confirmPassword'
                });
                this.comparePassword(e.target.value, password);
              }}
              showPassword={this.state.showConfirmPassword}
              togglePasswordVisibility={() => {
                this.togglePasswordVisibility('showConfirmPassword');
              }}
              required
            ></PasswordInput>
            <ButtonWithLoader buttonText="Register" loading={loading} />
            <p className="mb-1 mt-4 text-center">
              By registering, you agree to Vidami's{' '}
            </p>
            <p className="mb-0 text-center">
              <a
                href="https://vidami.com/policies/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Terms and Conditions </strong>
              </a>
              and{' '}
              <a
                href="https://vidami.com/policies/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Privacy Policy.</strong>
              </a>
            </p>
            <div className="login-button-link">
              Already have an account?{' '}
              <a
                href="/signin"
                onClick={() => {
                  let eventProperties = {
                    pageUrl: process.env.REACT_APP_PUBLICURL + '/signin',
                    pageTitle: 'Sign-In Page',
                    referrerUrl: document.referrer
                  };
                  sendAmplitudeData('Visited Sign-In Page', eventProperties);
                }}
              >
                Login
              </a>
            </div>
          </form>
        </div>
      );
    }
  }
);
