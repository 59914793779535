import {
  putResolve,
  take,
  takeLatest,
  call,
  spawn,
  takeEvery
} from 'redux-saga/effects';
import { api } from '../../helper/api';
import { adminDashboardUserActions } from './store';
import { toast } from 'react-toastify';

export function* userDetailsSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        adminDashboardUserActions.adminLoadUserData().type
      );
      let result = yield call(api, `/api/user-devices/${data}`, 'GET');
      if (result.data) {
        result.data.devices &&
          result.data.devices.forEach(device => {
            let user = { ...result.data };
            delete user.devices;
            device.user = user;
          });
        yield putResolve(
          adminDashboardUserActions.loadData({
            user: result.data,
            deviceList: result.data.devices
          })
        );
      } else {
        yield putResolve(
          adminDashboardUserActions.loadData({
            user: {},
            deviceList: []
          })
        );
      }
    } catch (err) {
      console.log(err);
      yield putResolve(
        adminDashboardUserActions.loadData({
          user: {},
          deviceList: []
        })
      );
    }
  }
}

export function* editDeviceSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        adminDashboardUserActions.adminEditUserDevice().type
      );
      if (window.confirm('Are you sure you want to update user details?')) {
        let result = yield call(
          api,
          `/api/admin-update-device-details`,
          'PUT',
          data
        );
        if (result.success === true) {
          toast.success(result.message, {
            className: 'toast-success',
            style: { width: '25rem' }
          });
          yield putResolve(adminDashboardUserActions.updateList(data));
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export function* deleteDeviceSaga() {
  while (true) {
    try {
      const { payload: device } = yield take(
        adminDashboardUserActions.adminDeleteUserDevice().type
      );
      if (window.confirm('Are you sure you want to delete this device?')) {
        let result = yield call(
          api,
          `/api/admin-delete-device`,
          'DELETE',
          device
        );
        if (result.success === true) {
          toast.success(result.message, {
            className: 'toast-success',
            style: { width: '25rem' }
          });
          yield putResolve(adminDashboardUserActions.deleteDevice(device));
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default function* adminDashboardUserSaga() {
  yield spawn(editDeviceSaga);
  yield spawn(deleteDeviceSaga);
  yield spawn(userDetailsSaga);
}
