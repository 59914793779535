import { state } from '../../store';
import { isCorrectPattern } from './helper';

export const [
  registerDevicesActions,
  registerDevicesSelector,
  registerDevicesState
] = state('registerDevices', {
  initial: {
    deviceSerialNumber: '',
    errorMessage: '',
    deviceName: 'Vidami',
    loading: false,
    deviceId: ''
  },
  on: {
    loadError: (_, data) => {
      let newStoreObj = { ..._ };
      newStoreObj.errorMessage = data;
      return { ...newStoreObj };
    },
    handleDeviceSerialNumberChange: (_, data) => {
      let newStoreObj = { ..._ };

      // pattern to check correct sr/no

      if (!isCorrectPattern(data.deviceSerialNumber, data.deviceName)) {
        newStoreObj.errorMessage =
          'Please enter correct Serial number for selected modal.';
      } else {
        newStoreObj.errorMessage = '';
      }
      newStoreObj.deviceSerialNumber = data.deviceSerialNumber;
      return { ...newStoreObj };
    },
    deviceChangeHandler: (_, data) => {
      let newStoreObj = { ..._ };
      newStoreObj.deviceName = data.deviceName;

      if (
        data.deviceSerialNumber &&
        !isCorrectPattern(data.deviceSerialNumber, data.deviceName)
      ) {
        newStoreObj.errorMessage =
          'Please enter correct Serial number for selected modal.';
      } else {
        newStoreObj.errorMessage = '';
      }
      return { ...newStoreObj };
    },
    setError: (_, error) => {
      let newStoreObj = { ..._ };
      newStoreObj.errorMessage = error || '';

      return { ...newStoreObj };
    },
    setLoading: (_, payload) => {
      const newStoreObj = { ..._ };
      newStoreObj.loading = payload;
      return { ...newStoreObj };
    },
    setDeviceId: (_, data) => {
      let newStoreObj = { ..._ };
      newStoreObj.deviceId = data;
      return { ...newStoreObj };
    }
  },
  events: ['storeDevice', 'getSingleDeviceDetails', 'updateDevice']
});
