import { putResolve, take, call, spawn, select } from 'redux-saga/effects';
import { signUpActions, signUpSelector } from './store';
import { api } from '../helper/api';
import history from '../helper/history';
import {
  sendAmplitudeData,
  setAmplitudeUserId
} from '../../utilities/amplitude';
import { state } from '../../store';

export function* loadUser() {
  while (true) {
    try {
      yield take(signUpActions.loadUserAction().type);
      const apiData = yield call(api, `/api/user`);
      if (apiData.data) {
        yield putResolve(signUpActions.load(apiData.data));
        // localStorage.setItem('login', true);
      } else if (apiData.message) {
        localStorage.setItem('login', false);
        localStorage.removeItem('jwtToken');
        yield putResolve(signUpActions.load(null));
        yield putResolve(signUpActions.loadError(apiData.message));
      }
    } catch (err) {
      yield putResolve(signUpActions.load(null));
      console.log(err);
    }
  }
}

export function* loginUserSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(signUpActions.loginUser().type);
      yield putResolve(signUpActions.setLoading(true));
      let apiData = yield call(api, `/api/auth/local`, 'POST', data);
      if (apiData.message && apiData.success) {
        yield putResolve(signUpActions.loadCredentialError(apiData.message));
        yield putResolve(signUpActions.setLoading(false));
      } else if (apiData.data) {
        setAmplitudeUserId(data.username);
        sendAmplitudeData('Logged in locally');
        localStorage.setItem('jwtToken', apiData.token);
        localStorage.setItem('login', true);
        if (apiData.data.isAdmin) {
          history.push('/admin-dashboard');
        } else if (apiData.data.userDevicesExists) {
          sendAmplitudeData('Visited the dashboard');
          history.push('/dashboard');
        } else {
          sendAmplitudeData('Visited the add device page');
          history.push('/onboarding/step3/device');
        }
      } else {
        history.push(`/error-page/?error=${apiData.providerName}_error`);
        yield putResolve(signUpActions.setLoading(true));
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export function* signUpUserSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(signUpActions.signUpUser().type);

      let apiData = yield call(api, `/api/auth/local`, 'POST', data);
      yield putResolve(signUpActions.setLoading(true));

      if (apiData.message && !apiData.isRegistered) {
        history.push(`/error-page/?error=${apiData.providerName}_error`);
        yield putResolve(signUpActions.setLoading(false));
      } else if (apiData.message && apiData.isRegistered) {
        history.push('/error-page/?error=register_error');
      }
      if (apiData.data) {
        // setAmplitudeUserId(apiData.data.email)
        sendAmplitudeData('Registered in the app', data.eventProperties);
        sendAmplitudeData('Visited the add device page');
        localStorage.setItem('jwtToken', apiData.token);
        localStorage.setItem('login', true);
        history.push('/onboarding/step3/device');
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export function* resetPasswordUserSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(
        signUpActions.resetPasswordEvent().type
      );
      yield putResolve(signUpActions.setLoading(true));
      let apiData = yield call(api, `/api/auth/reset-password`, 'POST', data);

      if (apiData.message !== 'Success') {
        yield putResolve(signUpActions.loadCredentialError(apiData.message));
        yield putResolve(signUpActions.setLoading(false));
      } else {
        setAmplitudeUserId(data.username);
        sendAmplitudeData('Resetted the password', data.eventProperties);
        yield putResolve(
          signUpActions.loadSuccessMessage(
            'Please check your email for the link!'
          )
        );
        setTimeout(() => {
          history.push('/signin');
        }, 5000);
        yield putResolve(signUpActions.setLoading(false));
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export function* changePasswordUserSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(signUpActions.changePassword().type);
      yield putResolve(signUpActions.setLoading(true));
      yield call(api, `/api/auth/change-password`, 'POST', data);
      history.push('/signin');

      // if(apiData.status && apiData.status === 401){
      //   yield putResolve(signUpActions.loadError("Email or password incorrect !"))
      // }else if(apiData.data){
      //   yield putResolve(signUpActions.load(apiData.data));
      //   history.push("/login");
      // }
    } catch (err) {
      console.log(err);
    }
  }
}

export function* validateTokenSaga() {
  while (true) {
    try {
      const { payload: data } = yield take(signUpActions.validateToken().type);

      let apiData = yield call(api, `/api/auth/validate-token`, 'POST', data);

      if (apiData.message !== 'Success') {
        history.push('/reset-password-error');
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export function* checkRegisteredDevicesSaga() {
  while (true) {
    try {
      yield take(signUpActions.checkRegisteredDevices().type);
      const apiData = yield call(api, `/api/auth/check-registered-devices`);
      const user = yield select(signUpSelector(state => state.user));
      if (user.isAdmin) {
        history.push('/admin-dashboard');
      } else if (apiData.userDevicesExists === true) {
        history.push('/dashboard');
      } else {
        history.push('/onboarding/step3/device');
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default function* userAuthPageSaga() {
  yield spawn(loadUser);
  yield spawn(loginUserSaga);
  yield spawn(signUpUserSaga);
  yield spawn(resetPasswordUserSaga);
  yield spawn(changePasswordUserSaga);
  yield spawn(validateTokenSaga);
  yield spawn(checkRegisteredDevicesSaga);
}
