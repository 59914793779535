import xlsx from 'json-as-xlsx';

export const sortBy = sortType => (a, b) => {
  console.log(sortType, '', a, b);
  if (sortType === true) return a > b ? 1 : b > a ? -1 : 0;
  if (sortType === false) return a > b ? -1 : b > a ? 1 : 0;
};

//data-->feildName, sortBy(String or number)

export const sorting = (arr, data) => {
  console.log(arr);
  console.log(data);
  arr.sort((a, b) => {
    if (data.sortBy === 'STRING') {
      let aName = a[data.fieldName].toLowerCase();
      let bName = b[data.fieldName].toLowerCase();
      return sortBy(data.sortType)(aName, bName);
    }
    if (data.sortBy === 'INTEGER') {
      let aScore = parseInt(a[data.fieldName]);
      let bScore = parseInt(b[data.fieldName]);
      return sortBy(data.sortType)(aScore, bScore);
    }
    if (data.sortBy === 'DATE') {
      let aDate = new Date(a[data.fieldName]);
      let bDate = new Date(b[data.fieldName]);
      return sortBy(data.sortType)(aDate, bDate);
    }
    if (data.sortBy === 'FLOAT') {
      let aFloat = parseFloat(a[data.fieldName]);
      let bFloat = parseFloat(b[data.fieldName]);
      return sortBy(data.sortType)(aFloat, bFloat);
    }
  });
  return arr;
};

export const sendVidamiMessage = detail => {
  const onVidamiStateChange = new CustomEvent('onvidamistatechange', {
    bubbles: true,
    detail
  });
  document.dispatchEvent(onVidamiStateChange);
};

export const downloadExcelSheet = (name, columnPropMap, sheetName, data) => {
  let actualData = [
    {
      sheet: sheetName,
      columns: columnPropMap,
      content: data
    }
  ];
  let settings = {
    fileName: name, // Name of the resulting spreadsheet
    extraLength: 5, // A bigger number means that columns will be wider
    writeMode: 'writeFile', // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
    RTL: true // Display the columns from right-to-left (the default value is false)
  };

  xlsx(actualData, settings);
};
