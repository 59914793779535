//base imports and third party
import React from 'react';
import { connect } from 'react-redux';
import { signUpSelector } from './components/authentication/store';
import { initAmplitude } from './utilities/amplitude';
import { ToastContainer } from 'react-toastify';

//component
import { Header } from './components/header';
import { DashboardRoutes } from './Layout/dashboardRoutes';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//services and utils
import { state } from './store';

//css
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

export const App = connect(
  state.select({
    user: signUpSelector(state => state.user, [])
  })
)(
  class App extends React.Component {
    render() {
      initAmplitude();
      const pageLocation = window.location.pathname;
      const { user } = this.props;
      return (
        <Router>
          {pageLocation === '/onboarding/step3/device' ||
          pageLocation === '/onboarding/step4/install' ||
          pageLocation === '/onboarding/step5/completed' ||
          pageLocation === '/dashboard' ||
          pageLocation === '/add-device' ||
          pageLocation.includes('/admin-dashboard') ||
          pageLocation.includes('/edit-device/') ? (
            <>
              <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Header user={user} />
            </>
          ) : (
            ''
          )}
          <DashboardRoutes user={user} />
        </Router>
      );
    }
  }
);
