import { state } from '../../../store';

const initialTableState = {
  deviceList: []
};

export const [
  adminDashboardUserActions,
  adminDashboardUserSelector,
  adminDashboardUserState
] = state('adminDashboardUser', {
  initial: {
    user: {},
    tableState: initialTableState,
    hasLoadedData: false
  },
  on: {
    loadData: (_, data) => {
      let newStoreObj = { ..._ };
      newStoreObj.tableState = { ...newStoreObj.tableState };
      newStoreObj.hasLoadedData = true;
      newStoreObj.user = data.user;
      newStoreObj.tableState.deviceList = data.deviceList;
      return { ...newStoreObj };
    },

    updateList: (_, editedRow) => {
      let newStoreObj = { ..._ };
      newStoreObj.tableState = { ...newStoreObj.tableState };
      const deviceList = newStoreObj.tableState.deviceList;
      const foundIndex = deviceList.findIndex(
        device => device.id === editedRow.id
      );
      deviceList.splice(foundIndex, 1, editedRow);

      newStoreObj.tableState.deviceList = [...deviceList];

      return { ...newStoreObj };
    },

    deleteDevice: (_, deletedRow) => {
      let newStoreObj = { ..._ };
      newStoreObj.tableState = { ...newStoreObj.tableState };
      const deviceList = newStoreObj.tableState.deviceList;
      const foundIndex = deviceList.findIndex(
        device => device.id === deletedRow.id
      );
      deviceList.splice(foundIndex, 1);

      newStoreObj.tableState.deviceList = [...deviceList];

      return { ...newStoreObj };
    }
  },
  events: [
    'adminLoadUserData',
    'adminLoadUserDevices',
    'adminEditUserDevice',
    'adminDeleteUserDevice'
  ]
});
