//base imports and third party
import React from 'react';
import { connect } from 'react-redux';

//services and utils
import history from './helper/history';
import { state } from '../store';
import { signUpActions, signUpSelector } from './authentication/store';

//components
import Stepper from './stepper';
import { ButtonWithLoader } from './button';
import { sendAmplitudeData } from '../utilities/amplitude';

//assets and css
import vidamiLogo from '../assets/img/New-Vidami-Logo.png';

export const HomePage = connect(
  state.select({
    loading: signUpSelector(state => state.loading, false)
  }),
  state.actions(signUpActions, ['setLoading'])
)(
  class HomePage extends React.Component {
    componentDidMount() {
      document.title = 'Welcome | Vidami';
      let eventProperties = {
        pageUrl: window.location.href,
        pageTitle: 'Welcome-Page',
        referrerUrl: document.referrer
      };
      sendAmplitudeData('Visited Home Page', eventProperties);
      let isLoginUser = localStorage.getItem('login');
      if (JSON.parse(isLoginUser) === true) {
        history.push('/dashboard');
      }
    }
    handleRedirect = () => {
      this.props.setLoading(true);
      history.push('/onboarding/step2/register');
      let eventProperties = {
        pageUrl: window.location.href,
        pageTitle: 'SignUp-Page',
        referrerUrl: document.referrer
      };
      sendAmplitudeData('Visited SignUp Page', eventProperties);
    };
    render() {
      return (
        <div className="homepage-container">
          <img
            src={vidamiLogo}
            className="register-account-company-logo-style"
            alt="vidamiLogo"
          />
          <Stepper activeSection={'welcome page'} />
          <p className="homepage-title">
            Welcome to Vidami <br /> The Future of Online Learning
          </p>
          <p className="homepage-account-creation-link mb-0">
            Let's Start Setting Up Your Vidami Account!
          </p>
          <div onClick={this.handleRedirect}>
            <ButtonWithLoader
              buttonText="Get Started"
              loading={this.props.loading}
            />
          </div>
        </div>
      );
    }
  }
);
