import { state } from '../../store';

const initialLoaderState = false;

export const [signUpActions, signUpSelector, signUpState] = state('signUp', {
  initial: {
    user: {},
    err: '',
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    passwordErr: '',
    credentialError: '',
    successMessage: '',
    loading: initialLoaderState
  },
  on: {
    load: (_, data) => {
      let newStoreObj = { ..._ };

      if (data && data.profile) {
        newStoreObj.user = data.profile;
      } else {
        newStoreObj.user = data;
      }

      return { ...newStoreObj };
    },
    loadError: (_, data) => {
      let newStoreObj = { ..._ };

      newStoreObj.err = data;
      return { ...newStoreObj };
    },
    handleEmailChange: (_, data) => {
      let newStoreObj = { ..._ };

      newStoreObj.email = data.target.value;

      return { ...newStoreObj };
    },
    handlePasswordChange: (_, data) => {
      let newStoreObj = { ..._ };

      const { fieldName, field } = data;

      newStoreObj[fieldName] = field.target.value;

      return { ...newStoreObj };
    },
    handleNameChange: (_, data) => {
      let newStoreObj = { ..._ };

      newStoreObj.name = data.target.value;

      return { ...newStoreObj };
    },
    loadPasswordError: (_, data) => {
      let newStoreObj = { ..._ };

      newStoreObj.passwordErr = data;
      return { ...newStoreObj };
    },
    loadCredentialError: (_, data) => {
      let newStoreObj = { ..._ };
      newStoreObj.credentialError = data;
      return { ...newStoreObj };
    },
    loadSuccessMessage: (_, data) => {
      let newStoreObj = { ..._ };
      newStoreObj.successMessage = data;
      return { ...newStoreObj };
    },
    clearFields: (_, data) => {
      let newStoreObj = { ..._ };
      newStoreObj.email = '';
      newStoreObj.password = '';
      newStoreObj.confirmPassword = '';
      newStoreObj.name = '';
    },
    setLoading: (_, payload) => {
      const newStoreObj = { ..._ };
      newStoreObj.loading = payload;
      return { ...newStoreObj };
    }
  },
  events: [
    'loadUserAction',
    'loginUser',
    'signUpUser',
    'resetPasswordEvent',
    'changePassword',
    'validateToken',
    'checkRegisteredDevices'
  ]
});
