//base imports and third party
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/fontawesome-free-solid';

export const PasswordInput = ({
  label,
  showPassword,
  id,
  placeholder,
  togglePasswordVisibility,
  checkPassword,
  passwordErr,
  value,
  isPassword,
}) => {
  return (
    <div className="form-details">
      <label className="label" htmlFor="password">
        {label}
      </label>
      <div className="input-container">
        <input
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          className="input"
          id={id}
          required
          onChange={checkPassword}
          value={value}
        />
        <FontAwesomeIcon
          onClick={togglePasswordVisibility}
          icon={showPassword ? faEye : faEyeSlash}
          style={{
            float: 'right',
            alignSelf: 'end',
            position: 'absolute',
            right: '25px',
            top: '16px',
            zIndex: 2,
            cursor: 'pointer',
          }}
        />
      </div>
      {isPassword && (
        <p className="password-warning" style={{ marginTop: '1.5rem' }}>
          Must be 8 characters at least
        </p>
      )}
      <p className="text-danger mb-0">{passwordErr}</p>
    </div>
  );
};
