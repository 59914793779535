import { call } from 'redux-saga/effects';

export function* api(uri, method = 'GET', body = null) {
  let response;
  let authToken = localStorage.getItem('jwtToken');

  if (body) {
    response = yield call(fetch, uri, {
      method: method,
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken ? `Bearer ${authToken}` : ''
      },
      credentials: 'include'
    });
  } else {
    response = yield call(fetch, uri, {
      method: method || 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken ? `Bearer ${authToken}` : ''
      }
    });
  }
  try {
    const json = yield call(retrieveJSON, response);
    return json;
  } catch (error) {
    console.log(error);
  }
}
export function retrieveJSON(response) {
  return response.json();
}
