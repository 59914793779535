import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { Button, FormControl, Overlay, Tooltip } from 'react-bootstrap';
import { isCorrectPattern } from '../registerDevices/helper';
import defaultUserLogo from '../../assets/img/default-user.png';
import { capitalize, getFormattedDate } from '../../utilities/helpers';
import { Link } from 'react-router-dom';

export const VidamiModelDropDown = ({ modelName, isEmpty, onChange }) => {
  const [model, setModel] = useState(modelName);
  return (
    <select
      className="serial-number-select-list-style"
      onChange={e => {
        const value = e.target.value;
        setModel(value);
        onChange(value);
      }}
      value={model}
    >
      <option
        className="paddle-first-option"
        disabled={!isEmpty}
        value={'DEFAULT'}
      >
        Select the Pedal
      </option>
      <option value="Vidami">Vidami</option>
      <option value="Vidami Blue">Vidami Blue</option>
    </select>
  );
};

export const TableRowEdit = ({
  index,
  data,
  showUserDetails = true,
  onSave,
  onClose
}) => {
  const [device, setDevice] = useState(data);
  const [error, setError] = useState('');
  const validateSerialNumber = (serialNumber, model) => {
    const isValidPattern = isCorrectPattern(serialNumber, model);
    if (!isValidPattern) {
      setError('Please enter correct Serial number for selected modal.');
    } else {
      setError(null);
    }
  };
  const onSerailNumberChange = e => {
    validateSerialNumber(e.target.value, device.name);
    setDevice({ ...device, serialNumber: e.target.value });
  };
  const onModelChange = model => {
    validateSerialNumber(device.serialNumber, model);
    setDevice({ ...device, name: model });
  };
  return (
    <>
      <td>{index}</td>
      {showUserDetails && (
        <>
          <td>
            <img src={data.user.avatar || defaultUserLogo} />
            {data.user.name}
          </td>
          <td>{data.user.email}</td>
        </>
      )}
      <td style={{ textAlign: 'center' }}>
        <input
          style={{
            margin: 'auto',
            textAlign: 'center',
            height: '25px',
            width: '50%',
            padding: '0'
          }}
          placeholder="SerialNumber"
          aria-label="SerialNumber"
          value={device.serialNumber}
          onChange={onSerailNumberChange}
        />
        {error && <div className="table_error_message">{error}</div>}
      </td>
      <td style={{ textAlign: 'center' }}>
        <VidamiModelDropDown modelName={data.name} onChange={onModelChange} />
      </td>
      {showUserDetails && <td>{capitalize(data.user.provider)}</td>}
      <td>{getFormattedDate(new Date(data.updatedAt))}</td>
      <td>
        <div className="table-action-container">
          <FontAwesomeIcon
            className={'table-action' + (error ? ' disable-icon' : '')}
            icon="save"
            onClick={() => onSave(device)}
          />
          <FontAwesomeIcon
            className="table-action"
            icon="times"
            onClick={onClose}
          />
        </div>
      </td>
    </>
  );
};

export const TableRow = ({
  index,
  data,
  showUserDetails = true,
  onEdit,
  onDelete
}) => {
  return (
    <>
      <td>{index}</td>
      {showUserDetails && (
        <>
          <td>
            <img src={data.user.avatar || defaultUserLogo} />
            {data.user.name}
          </td>
          <td>{data.user.email}</td>
        </>
      )}
      <td style={{ textAlign: 'center' }}>{data.serialNumber}</td>
      <td style={{ textAlign: 'center' }}>{data.name}</td>
      {showUserDetails && <td>{capitalize(data.user.provider)}</td>}
      <td>{getFormattedDate(new Date(data.updatedAt))}</td>
      <td>
        <div className="table-action-container">
          <FontAwesomeIcon
            className="table-action"
            icon="edit"
            onClick={onEdit}
          />
          <FontAwesomeIcon
            className="table-action"
            icon="trash"
            onClick={onDelete}
          />
        </div>
      </td>
    </>
  );
};

export const TableRowUsers = ({ index, data, onEdit, onDelete }) => {
  return (
    <>
      <td>{index}</td>
      <td>
        <Link to={`/admin-dashboard/user/${data.id}`}>
          <img src={data.avatar || defaultUserLogo} />
          {data.name}
        </Link>
      </td>
      <td>{data.email}</td>
      <td>{capitalize(data.provider)}</td>
      <td>{getFormattedDate(new Date(data.createdAt))}</td>
    </>
  );
};

export const NoDataTableRow = () => {
  return (
    <>
      <td style={{ textAlign: 'center' }} colSpan={8}>
        No Data
      </td>
    </>
  );
};
