//base imports and third party
import React from 'react';
import { connect } from 'react-redux';

//services and utils
import { state } from '../store';
import { signUpActions, signUpSelector } from './authentication/store';
import { sendVidamiMessage } from './helper';
import history from './helper/history';

export const AuthorizeUser = connect(
  state.select({
    user: signUpSelector(state => state.user, {})
  }),
  state.actions(signUpActions, ['checkRegisteredDevices', 'loadUserAction'])
)(
  class AuthorizeUser extends React.Component {
    constructor(props) {
      super(props);
      localStorage.setItem('jwtToken', this.props.match.params.token);
      const token = localStorage.getItem('jwtToken');
      if (token.length) {
        localStorage.setItem('login', true);
      }
      this.props.loadUserAction();
    }
    componentDidMount() {}
    render() {
      const { user, deviceData } = this.props;
      const search = this.props.location.search;
      if (search && search.includes('extension')) {
        if (user.id) {
          sendVidamiMessage({ user });
        }
      } else this.props.checkRegisteredDevices();
      return <div>redirecting...</div>;
    }
  }
);
