//base imports and third party
import React from 'react';

//services and utils

//components
import Stepper from '../stepper';

//assets and css
import Chrome from '../../assets/img/chrome.png';
import Safari from '../../assets/img/safari.png';
import IOS from '../../assets/img/app-store.png';
import VidamiMobile from '../../assets/img/vidami-logo-normal.png';
import Apple from '../../assets/img/apple-white.png';
import { state } from '../../store';
import { registerDevicesSelector } from '../registerDevices/store';
import { connect } from 'react-redux';
import { dashboardActions, dashboardSelector } from '../dashboard/store';
import ExtensionLinks from './extensionLinks';
import {
  CHROME_EXTENSION,
  IOS_SAFARI_EXTENSION,
  SAFARI_EXTENSION
} from '../../utilities/constants';
import { isIOS } from '../../utilities/helpers';
import history from '../helper/history';

class InstallExtension extends React.Component {
  componentDidMount() {
    document.title = 'Install Extension | Vidami';
    this.props.loadDevicesData();
  }

  chromeLinkObject = {
    image: Chrome,
    buttonImage: Chrome,
    buttonText: 'Click Here to Install the Vidami Extension',
    extensionLink: CHROME_EXTENSION,
    installText: 'Install Vidami Chrome Extension'
  };

  safariLinkObject = {
    image: Safari,
    buttonImage: Safari,
    buttonText: 'Click Here to Install the Vidami Extension',
    extensionLink: SAFARI_EXTENSION,
    installText: 'Install Vidami Safari Extension'
  };

  iosSafariLinkObject = {
    image: VidamiMobile,
    buttonText: 'Click Here to Install Vidami Mobile for Safari',
    extensionLink: IOS_SAFARI_EXTENSION,
    installText: 'Install Vidami Mobile for Safari'
  };

  render() {
    const { deviceData, hasLoadedData } = this.props;

    if (deviceData.length) {
      const hasVidamiBlue = deviceData.some(data =>
        data.name.includes('Vidami Blue')
      );

      let isChrome =
        /Chrome/.test(navigator.userAgent) &&
        /Google Inc/.test(navigator.vendor);

      let isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function(p) {
          return p.toString() === '[object SafariRemoteNotification]';
        })(
          !window['safari'] ||
            (typeof safari !== 'undefined' && window['safari'].pushNotification)
        );
      let isIos = isIOS();
      let isFirefox = typeof InstallTrigger !== 'undefined';

      const hasLowerIOSVersion =
        isIos &&
        /iPad|iPhone|iPod/.test(navigator.platform) &&
        +navigator.userAgent
          .match(/OS (\d)?\d_\d(_\d)?/i)[0]
          .split('_')[0]
          .replace('OS ', '') < 15;

      switch (true) {
        case !!isChrome:
          return <ExtensionLinks linkObjects={[this.chromeLinkObject]} />;
        case !!isSafari:
          return <ExtensionLinks linkObjects={[this.safariLinkObject]} />;
        case !!isIos:
          if (hasVidamiBlue && !hasLowerIOSVersion) {
            return <ExtensionLinks linkObjects={[this.iosSafariLinkObject]} />;
          } else {
            return (
              <div className="firefox-extension-container">
                <Stepper activeSection={'install extension'} />
                <p className="registration-page-title">Vidami Extensions</p>
                <p className="registration-page-subTitle installSoftware-page-subTitle ">
                  Install {hasVidamiBlue ? '' : `desktop`} extensions for Vidami{' '}
                  {hasVidamiBlue ? 'Blue' : ''}
                </p>
                <div className="extension-buttons-container">
                  <div
                    className="extension-card"
                    onClick={() => {
                      window.open(CHROME_EXTENSION, '_blank');
                      window.location.href = `${process.env.REACT_APP_PUBLICURL}/onboarding/step5/completed`;
                    }}
                  >
                    <img src={Chrome} alt="" />
                    <p className="mb-0">Download Extension</p>
                  </div>
                  <div
                    className="extension-card"
                    onClick={() => {
                      window.open(SAFARI_EXTENSION, '_blank');
                      window.location.href = `${process.env.REACT_APP_PUBLICURL}/onboarding/step5/completed`;
                    }}
                  >
                    <img src={Safari} alt="" />
                    <p className="mb-0">Download Extension</p>
                  </div>
                  {hasVidamiBlue && hasLowerIOSVersion && (
                    <div
                      className="extension-card"
                      onClick={() => {
                        window.open(IOS_SAFARI_EXTENSION, '_blank');
                        window.location.href = `${process.env.REACT_APP_PUBLICURL}/onboarding/step5/completed`;
                      }}
                    >
                      <img src={VidamiMobile} alt="" />
                      <p className="mb-0">Download Vidami Mobile for Safari</p>
                    </div>
                  )}
                </div>
              </div>
            );
          }
        case !!isFirefox:
          return (
            <div className="firefox-extension-container">
              <Stepper activeSection={'install extension'} />
              <p className="registration-page-title">Vidami Extension</p>
              <p className="registration-page-subTitle installSoftware-page-subTitle ">
                Vidami Extension will not work with Firefox.
              </p>
              <div className="extension-buttons-container">
                <div
                  className="extension-card"
                  onClick={() => {
                    window.open(CHROME_EXTENSION, '_blank');
                    window.location.href = `${process.env.REACT_APP_PUBLICURL}/onboarding/step5/completed`;
                  }}
                >
                  <img src={Chrome} alt="" />
                  <p className="mb-0">Download Extension</p>
                </div>
                <div
                  className="extension-card"
                  onClick={() => {
                    window.open(SAFARI_EXTENSION, '_blank');
                    window.location.href = `${process.env.REACT_APP_PUBLICURL}/onboarding/step5/completed`;
                  }}
                >
                  <img src={Safari} alt="" />
                  <p className="mb-0">Download Extension</p>
                </div>
                {hasVidamiBlue && (
                  <div
                    className="extension-card"
                    onClick={() => {
                      window.open(IOS_SAFARI_EXTENSION, '_blank');
                      window.location.href = `${process.env.REACT_APP_PUBLICURL}/onboarding/step5/completed`;
                    }}
                  >
                    <img src={VidamiMobile} alt="" />
                    <p className="mb-0">Download Vidami Mobile for Safari</p>
                  </div>
                )}
              </div>
            </div>
          );
        default:
          return (
            <div className="firefox-extension-container">
              <Stepper activeSection={'install extension'} />
              <p className="registration-page-title">Vidami Extension</p>
              <p className="registration-page-subTitle installSoftware-page-subTitle ">
                Vidami Extension will not work with this browser.
              </p>
              <div className="extension-buttons-container">
                <div
                  className="extension-card"
                  onClick={() => {
                    window.open(CHROME_EXTENSION, '_blank');
                    window.location.href = `${process.env.REACT_APP_PUBLICURL}/onboarding/step5/completed`;
                  }}
                >
                  <img src={Chrome} alt="" />
                  <p className="mb-0">Download Extension</p>
                </div>
                <div
                  className="extension-card"
                  onClick={() => {
                    window.open(SAFARI_EXTENSION, '_blank');
                    window.location.href = `${process.env.REACT_APP_PUBLICURL}/onboarding/step5/completed`;
                  }}
                >
                  <img src={Safari} alt="" />
                  <p className="mb-0">Download Extension</p>
                </div>
                {hasVidamiBlue && (
                  <div
                    className="extension-card"
                    onClick={() => {
                      window.open(IOS_SAFARI_EXTENSION, '_blank');
                      window.location.href = `${process.env.REACT_APP_PUBLICURL}/onboarding/step5/completed`;
                    }}
                  >
                    <img src={VidamiMobile} alt="" />
                    <p className="mb-0">Download Vidami Mobile for Safari</p>
                  </div>
                )}
              </div>
            </div>
          );
      }
    } else {
      if (hasLoadedData) history.push('/dashboard');
      else return null;
    }
  }
}

export default connect(
  state.select({
    deviceName: registerDevicesSelector(state => state.deviceName, ''),
    deviceData: dashboardSelector(state => state.deviceData, []),
    hasLoadedData: dashboardSelector(state => state.hasLoadedData, false)
  }),
  state.actions(dashboardActions, ['loadDevicesData'])
)(InstallExtension);
