//base imports and third party
import React from 'react';

//services and utils
import history from '../helper/history';

//components
import Stepper from '../stepper';
//assets and css
import success from '../../assets/img/tick-box.png';
import { sendAmplitudeData } from '../../utilities/amplitude';

export default class CompleteOnboarding extends React.Component {
  componentDidMount() {
    document.title = 'Onboarding Complete | Vidami';
  }

  render() {
    return (
      <div className="complete-onboarding-container">
        <Stepper activeSection={'completed'} />
        <div className="complete-onboarding-wrapper">
          <img src={success} alt="" />
          <p className="complete-onboading-message">
            Congratulations!
            <span role="img" aria-label="information">
              🎉
            </span>
          </p>
          <p className="complete-onboading-subtext">
            Your account has been successfully created.
          </p>
          <button
            className="complete-onboading-button"
            onClick={() => {
              let dashboard_event_properties = {
                "pageUrl": process.env.REACT_APP_PUBLICURL + '/dashboard',
                "pageTitle": "Dashboard-Page",
                "referrerUrl": document.referrer,
              }
              history.push('/dashboard')
              sendAmplitudeData(`Visited the dashboard`, dashboard_event_properties);
            }}
          >
            Go to Dashboard
          </button>
        </div>
      </div>
    );
  }
}
