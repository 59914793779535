//base imports and third party
import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

//assets and css
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/theme.css';

//services and utils

//components
import { SignIn } from '../components/authentication/signin';
import { ResetPassword } from '../components/authentication/resetPassword';
import { ConfirmPassword } from '../components/authentication/confirmPassword';
import { HomePage } from '../components/homePage';
import { Registration } from '../components/authentication/registration';
import { RegisterDevice } from '../components/registerDevices/registerDevices';
import { Dashboard } from '../components/dashboard/dashboard';
import { ErrorPage } from '../components/authentication/errorPage';
import CompleteOnboarding from '../components/onboardingComplete/complete-onboarding';
import { LinkErrorPage } from '../components/authentication/errorPages/resetPasswordErrorPage';
import { EditDevice } from '../components/registerDevices/editDevice';
import InstallExtension from '../components/installExtension/install-extension';
import { AuthorizeUser } from '../components/authorizeUser';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import { AdminDashBoard } from '../components/adminDashboard/adminDashboard';
import { AdminSignin } from '../components/adminSignin/adminSignin';
import { ExtensionSignIn } from '../components/authentication/extension-signin';
import { AdminDashBoardUsers } from '../components/adminDashboard/adminDashboardUsers';
import { AdminDashBoardUserDetail } from '../components/adminDashboard/adminDashboardUser/adminDashboardUserDetail';

export const DashboardRoutes = connect()(
  class DashboardRoutes extends React.Component {
    render() {
      return (
        <Switch>
          {/* Private Routes */}
          <PrivateRoute
            path="/onboarding/step3/device"
            component={RegisterDevice}
          />
          <PrivateRoute
            path="/onboarding/step4/install"
            component={InstallExtension}
          />
          <PrivateRoute
            path="/onboarding/step5/completed"
            component={CompleteOnboarding}
          />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/add-device">
            <RegisterDevice addDevice={true} />
          </PrivateRoute>
          <PrivateRoute path="/edit-device/:id" component={EditDevice} />

          {/* Public Routes */}
          <PublicRoute exact path="/" component={HomePage} />
          <PublicRoute
            path="/extension/onboarding/step2/login"
            component={ExtensionSignIn}
          />
          <PublicRoute
            path="/onboarding/step2/register"
            component={Registration}
          />
          <Route path="/auth/:token" component={AuthorizeUser} />
          <PublicRoute path="/signin" component={SignIn} />
          <PublicRoute path="/reset-password-error" component={LinkErrorPage} />
          <PublicRoute
            path="/confirm-password/:id"
            component={ConfirmPassword}
          />
          <PublicRoute path="/reset-password" component={ResetPassword} />
          <PublicRoute path="/error-page" component={ErrorPage} />
          <PrivateRoute
            path="/admin-dashboard"
            exact
            requireAdmin
            component={AdminDashBoard}
          />
          <PrivateRoute
            path="/admin-dashboard/users"
            exact
            requireAdmin
            component={AdminDashBoardUsers}
          />
          <PrivateRoute
            path="/admin-dashboard/user/:id"
            exact
            requireAdmin
            component={AdminDashBoardUserDetail}
          />
        </Switch>
      );
    }
  }
);
