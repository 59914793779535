import { state } from '../../store';

export const [dashboardActions, dashboardSelector, dashboardState] = state(
  'dashboard',
  {
    initial: {
      deviceData: [],
      hasLoadedData: false
    },
    on: {
      loadData: (_, data) => {
        let newStoreObj = { ..._ };
        newStoreObj.hasLoadedData = true;
        newStoreObj.deviceData = data;
        return { ...newStoreObj };
      }
    },
    events: ['loadDevicesData', 'editDevice', 'deleteDevice']
  }
);
