//base imports and third party
import React, { Component } from 'react';
import { connect } from 'react-redux';

//services and utils
import { state } from '../../store';
import { signUpSelector } from '../authentication/store';
import { registerDevicesActions, registerDevicesSelector } from './store';

//components
import { ButtonWithLoader } from '../button';
import { isCorrectPattern } from './helper';

export const EditDevice = connect(
  state.select({
    err: signUpSelector(state => state.err, ''),
    user: signUpSelector(state => state.user, {}),
    deviceSerialNumber: registerDevicesSelector(
      state => state.deviceSerialNumber,
      ''
    ),
    errorMessage: registerDevicesSelector(state => state.errorMessage, ''),
    deviceName: registerDevicesSelector(state => state.deviceName, ''),
    deviceId: registerDevicesSelector(state => state.deviceId, ''),
    loading: registerDevicesSelector(state => state.loading, '')
  }),
  state.actions(registerDevicesActions, [
    'handleDeviceSerialNumberChange',
    'updateDevice',
    'deviceChangeHandler',
    'getSingleDeviceDetails',
    'setError'
  ])
)(
  class EditDevice extends Component {
    componentDidMount() {
      document.title = 'Edit Device | Vidami';
      this.props.getSingleDeviceDetails({ id: this.props.match.params.id });
    }
    submit = event => {
      event.preventDefault();
      //props
      const { user, deviceId, deviceSerialNumber, deviceName } = this.props;

      // pattern to check correct sr/no

      if (
        isCorrectPattern(deviceSerialNumber, deviceName) &&
        deviceName.length
      ) {
        this.props.setError(null);
        let eventProperties = {
          pageUrl: window.location.href,
          pageTitle: 'Edit-Device-Page',
          referrerUrl: document.referrer,
          deviceId: deviceSerialNumber
        };

        this.props.updateDevice({
          userId: user.id,
          id: deviceId,
          serialNumber: deviceSerialNumber,
          name: deviceName,
          eventProperties,
          email: user.email
        });
      }
    };

    render() {
      //props
      const {
        deviceSerialNumber,
        errorMessage,
        deviceName,
        loading
      } = this.props;

      //actions
      const {
        handleDeviceSerialNumberChange,
        deviceChangeHandler
      } = this.props;
      return (
        <div className={'registrationAddDevice-container'}>
          <p className="registration-page-title">Edit Device</p>
          <form
            className="registerDevice-container mb-5"
            onSubmit={this.submit}
          >
            <div className="form-details mt-4">
              <label className="label" htmlFor="serialNumber">
                Edit Serial Number
              </label>
              <div className="serial-number-input-wrapper">
                <select
                  className="serial-number-select-list-style"
                  onChange={data => {
                    deviceChangeHandler({
                      deviceName: data.target.value,
                      deviceSerialNumber
                    });
                  }}
                  value={deviceName}
                >
                  <option
                    className="paddle-first-option"
                    disabled
                    value={'DEFAULT'}
                  >
                    Select the Pedal
                  </option>
                  <option value="Vidami">Vidami</option>
                  <option value="Vidami Blue">Vidami Blue</option>
                </select>
                <input
                  name="serialNumber"
                  className="serial-number-input"
                  placeholder="Enter Serial Number"
                  required
                  value={deviceSerialNumber}
                  onChange={e => {
                    handleDeviceSerialNumberChange({
                      deviceSerialNumber: e.target.value,
                      deviceName
                    });
                  }}
                />
              </div>
            </div>
            <div
              style={{
                color: 'red',
                textAlign: 'center',
                height: '1.4rem',
                paddingTop: '0.5rem'
              }}
            >
              {errorMessage.length ? errorMessage : ''}
            </div>
            <ButtonWithLoader buttonText={'Update'} loading={loading} />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '1rem',
                fontWeight: 500
              }}
            >
              <a href="/dashboard">Cancel</a>
            </div>
          </form>
        </div>
      );
    }
  }
);
