//base imports and third party
import React from 'react';
import { connect } from 'react-redux';

//services and utils
import history from '../../helper/history';

//css and components
import '../../../assets/css/theme.css';

export const LinkErrorPage = connect()(
  class LinkErrorPage extends React.Component {
    render() {
      return (
        <div className="errorpage-container">
          <p className="errorpage-account-creation-link">
            This link is expired, if you are trying to reset your password,
            please try again.
          </p>
          <button
            className="errorpage-button"
            onClick={() => history.push('/reset-password')}
          >
            Back to reset password
          </button>
        </div>
      );
    }
  }
);
