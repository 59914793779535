//base imports and third party
import React from 'react';
import { connect } from 'react-redux';

//services and utils
import { signUpActions, signUpSelector } from './store';
import { state } from '../../store';

//components
import { ButtonWithLoader } from '../button';

//assets and css
import vidamiLogo from '../../assets/img/New-Vidami-Logo.png';

export const ResetPassword = connect(
  state.select({
    credentialError: signUpSelector(state => state.credentialError, []),
    email: signUpSelector(state => state.email, []),
    successMessage: signUpSelector(state => state.successMessage, []),
    loading: signUpSelector(state => state.loading)
  }),
  state.actions(signUpActions, [
    'load',
    'loginUser',
    'handleEmailChange',
    'handlePasswordChange',
    'resetPasswordEvent',
    'loadSuccessMessage',
    'loadCredentialError'
  ])
)(
  class ResetPassword extends React.Component {
    componentDidMount() {
      document.title = 'Forgot Password | Vidami';
    }

    handleSubmit = async e => {
      e.preventDefault();

      const { email } = this.props;
      if (email) {
        let eventProperties = {
          pageUrl: window.location.href,
          pageTitle: 'Reset-Password Page',
          referrerUrl: document.referrer
        };
        this.props.loadCredentialError('');
        this.props.loadSuccessMessage('');
        this.props.resetPasswordEvent({
          username: email.toLowerCase(),
          eventProperties
        });
      }
    };

    render() {
      const {
        handleEmailChange,
        email,
        credentialError,
        successMessage,
        loading
      } = this.props;

      return (
        <div className="password-reset-container">
          <form
            onSubmit={e => {
              this.handleSubmit(e);
            }}
            className="reset-password-form"
          >
            <div className="text-center mb-5">
              <img
                src={vidamiLogo}
                className="register-account-company-logo-style"
                alt="vidamiLogo"
              />
            </div>
            <p className="registration-page-title">Forgot Password?</p>
            <p className="registration-page-subTitle mt-2">
              Enter your email and we will send you a reset link
            </p>
            <div className="form-details">
              <input
                type="text"
                name="email"
                className="input"
                placeholder="Enter your e-mail"
                value={email}
                onChange={handleEmailChange}
              />
              {credentialError.length > 0 && (
                <p className="text-danger" style={{ marginTop: '10px' }}>
                  {this.props.credentialError}
                </p>
              )}
              {successMessage.length > 0 && (
                <p className="text-success" style={{ marginTop: '10px' }}>
                  {this.props.successMessage}
                </p>
              )}
              <ButtonWithLoader
                buttonText={'Send me the link'}
                loading={loading}
              />
              <div className="register-button-link">
                Don't have an account? <a href="/">Register</a>
              </div>
              <div className="forgot-password-button-link">
                <a href="/signin">Login</a>
              </div>
            </div>
          </form>
        </div>
      );
    }
  }
);
