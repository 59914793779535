import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import {
  signUpActions,
  signUpSelector
} from '../components/authentication/store';
import { sendVidamiMessage } from '../components/helper';
import history from '../components/helper/history';
import { state } from '../store';

export default connect(
  state.select({
    user: signUpSelector(state => state.user, {})
  }),
  state.actions(signUpActions, ['loadUserAction'])
)(
  class PublicRoute extends React.Component {
    componentDidMount() {
      this.props.loadUserAction();
    }
    render() {
      const { component: Component, user, ...rest } = this.props;
      const isAuthenticated = user.id;
      const isAdmin = rest.requireAdmin;
      const dashboardUrl = isAdmin ? '/admin-dashboard' : '/dashboard';
      if (isAuthenticated && rest.location.search.includes('extension')) {
        sendVidamiMessage({ user });
      }
      return (
        <Route
          {...rest}
          component={props => {
            return isAuthenticated ? (
              history.push(dashboardUrl)
            ) : (
              <Component {...props} />
            );
          }}
        />
      );
    }
  }
);
