import { spawn } from 'redux-saga/effects';
import adminDashboardUserSaga from '../components/adminDashboard/adminDashboardUser/saga';
import adminDashboardSaga from '../components/adminDashboard/saga';
import userAuthPageSaga from '../components/authentication/saga';
import dashboardSaga from '../components/dashboard/saga';
import devicesSaga from '../components/registerDevices/saga';

export default function* rootSaga() {
  yield spawn(userAuthPageSaga);
  yield spawn(devicesSaga);
  yield spawn(dashboardSaga);
  yield spawn(adminDashboardSaga);
  yield spawn(adminDashboardUserSaga);
}
